import { Skeleton, Stack, Typography, Rating } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../index.css';
import * as React from 'react';
import axios from 'axios';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'

import { VscReferences } from "react-icons/vsc";
import { CircularProgress } from "@mui/joy";
import { ArrowPathIcon, DocumentCheckIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid';
import { motion, AnimatePresence } from 'framer-motion';
import SparkAILogo from '../assets/sparkai_new_only_logo.png';
import { IoSend } from "react-icons/io5";
import HelixLoader from '../common_components/HelixLoader.jsx';
import { CustomMarkdownRenderer } from './ResponseRenderer';

import { UserContext } from '../pages/Chat';
import { MainUserContext } from '../App';
import { replaceCustomMarkdown, calcMaxInputLength } from '../utils/utils';
import { createChatName, createConversation, SparkAICall } from './helpers';
import 'ldrs/helix'

import confetti from 'canvas-confetti';
import ResponseRenderer from './ResponseRenderer';
import { FaLightbulb } from "react-icons/fa6";
import { Tooltip } from "@mui/material";
import PromptTipsModal from './PromptTipsModal';

function ActionMessage({ actionType, actionValue, content }) {
    React.useEffect(() => {
        console.log(`[ActionMessage] actionType: ${actionType} actionValue: ${actionValue} content: ${content}`);
    }, [actionType, actionValue, content]);

    if (actionType === "EXPLAIN_CONCEPT") {
        return (
            <div className="flex flex-col gap-4 p-4 rounded-xl border border-emerald-200 bg-gradient-to-br from-emerald-50/50 to-white">
                <div className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-emerald-100">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-emerald-600">
                            <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
                        </svg>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-semibold text-gray-800">Action Requested</span>
                        <span className="text-sm text-gray-500">Explain: {actionValue}</span>
                    </div>
                </div>
            </div>
        )
    }
    else if (actionType === "FIND_PAPERS") {
        return (
            <div className="flex flex-col gap-4 p-4 rounded-xl border border-blue-200 bg-gradient-to-br from-blue-50/50 to-white">
                <div className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-blue-100">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-blue-600">
                            <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                            <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z" />
                            <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z" />
                        </svg>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-semibold text-gray-800">Research Requested</span>
                        <span className="text-sm text-gray-500">Find papers about: {actionValue}</span>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <Markdown
                remarkPlugins={[[remarkGfm], [remarkMath]]} 
                rehypePlugins={[[rehypeKatex], [rehypeRaw]]}
                className="text-lg md:text-[0.95rem] text-gray-800 md:leading-10"
            >
                {content}
            </Markdown>
        )
    }
}

function ChatMessage({ role_id, content, lastSecondsElapsed, messageId, lastMessageId, reasoning, actionType, actionValue }) {
    const displayRole = role_id === 1 ? '' : 'SparkAI';
    const roleClass = role_id === 1 ? 'message-user' : 'message-bot';
    const containerClass = role_id === 1 ? 'message-container-user' : 'message-container-bot';
    const showTime = role_id === 2 && lastSecondsElapsed;

    const [isAction, setIsAction] = React.useState(null);
    const [messageActionType, setMessageActionType] = React.useState(null);
    const [messageActionValue, setMessageActionValue] = React.useState(null);

    const [ contentToDisplay, setContentToDisplay ] = React.useState(content);
    const { setMessageIdForSources, setSourcesDrawerOpen, sourcesDrawerOpen } = React.useContext(UserContext);
    const [rating, setRating] = React.useState(0);
    const [citations, setCitations] = React.useState(null);

    React.useEffect(() => {
        setIsAction(actionType != null);
        setMessageActionType(actionType);
        setMessageActionValue(actionValue);
    }, [actionType, actionValue, messageId, content]);

    // Fetch citations from the api (api/messages/citations/:message_id)
    React.useEffect(() => {
        if (messageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/messages/citations/${messageId}`)
                .then(response => {
                    setCitations(response.data);
                })
                .catch(error => {
                    console.error('Error fetching citations:', error);
                });
        }
    }, [messageId]);

    React.useEffect(() => {
        console.log(`[ChatMessage] Id: ${messageId} isAction: ${isAction} actionType: ${actionType} actionValue: ${actionValue}`);
    }, [isAction, actionType, actionValue]);

    React.useEffect(() => {
        if (messageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/messages/rating/${messageId}`)
                .then(response => {
                    if (response.data.status === "success") {
                        setRating(response.data.rating || 0);
                    }
                })
                .catch(error => {
                    console.error('Error fetching rating:', error);
                });
        }
    }, [messageId]);

    const handleOpenReferences = () => {
        console.log('Opening references for message:', lastMessageId);
        setMessageIdForSources(lastMessageId);
        if (sourcesDrawerOpen) {
            setSourcesDrawerOpen(false);
        } else
            setSourcesDrawerOpen(true);
    }

    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
        // Make API request to store rating
        axios.post(process.env.REACT_APP_API_URL + '/api/messages/rate', {
            message_id: messageId,
            rating: newValue
        });

        // Show confetti animation for high ratings
        if (newValue >= 4) {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }
    };
    
    const [markdownReasoning, setMarkdownReasoning] = React.useState(null);

    React.useEffect(() => {
        if (reasoning) {
            CustomMarkdownRenderer({ text: reasoning }).then(setMarkdownReasoning);
        }
    }, [reasoning]);

    const [isOpen, setIsOpen] = React.useState(false);
    const [height, setHeight] = React.useState(0);
    const contentRef = React.useRef(null);

    const toggleAccordion = () => {
        if (isOpen) {
            setHeight(0);
            setTimeout(() => setIsOpen(false), 300); // Match transition duration
        } else {
            setIsOpen(true);
            setHeight(contentRef.current?.scrollHeight || 0);
        }
    };

    return (
        <div className={`${containerClass} relative`}>
            {showTime ? (
                <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography level="body-sm" sx={{ whiteSpace: 'nowrap' }}>
                        {Math.round(lastSecondsElapsed)} secs
                    </Typography>
                </Stack>
            ) : (
                <div
                    className={`flex absolute top-6 
                            ${role_id === 2 ? `left-[-60px]` : ` 
                            right-[-60px]`}`}
                >
                </div>
            )}
            <div className={`${roleClass} w-full relative flex flex-col gap-2`}>
                {role_id === 2 && reasoning && (
                    <div className="hover:bg-green-50 rounded-2xl p-2 hover:cursor-pointer transition-all duration-300">
                        <div className="[&_summary::-webkit-details-marker]:hidden">
                            <div 
                                onClick={toggleAccordion}
                                className="flex justify-between items-center cursor-pointer px-4 py-2"
                            >
                                <div className="flex flex-row gap-2 text-sm md:text-base">
                                    <span className="border border-orange-500 px-2 rounded-md font-mono tracking-widest animated-gradient bg-gradient-to-r from-orange-600 to-yellow-500 bg-clip-text text-transparent">
                                        APEX
                                    </span>
                                    <span className="font-semibold animated-gradient bg-gradient-to-r from-orange-600 to-yellow-500 bg-clip-text text-transparent">
                                        My Thinking Process <span className="ml-2 text-xs text-green-500 bg-green-100 px-2 rounded-md">Click to expand</span>
                                    </span>
                                </div>
                                <span className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-orange-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </span>
                            </div>
                            <div 
                                style={{ height: isOpen ? `${Math.min(height, 400)}px` : '0px' }}
                                className="overflow-hidden transition-all duration-300 ease-in-out"
                            >
                                <div 
                                    ref={contentRef}
                                    className="px-4 py-2 my-4 overflow-y-auto border-l-[1px] border-orange-500 pl-6 max-h-[400px]"
                                >
                                    <span className="text-sm md:text-sm whitespace-pre-wrap text-gray-500">
                                        <ResponseRenderer 
                                            response={markdownReasoning} 
                                            citations={citations || {}}
                                            showFocus={false}
                                            textSize="text-[0.8rem]"
                                            textColor="gray-500"
                                            showCitations={false}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {role_id === 2 ? (
                    <ResponseRenderer 
                        response={content} 
                        citations={citations || {}}
                    />
                ) : (
                    <ActionMessage actionType={messageActionType} actionValue={messageActionValue} content={content} />
                )}
                {role_id === 2 &&
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-2 justify-start items-center">
                            <p
                                className="text-sm text-gray-700 font-semibold"
                            >
                                Is this answer helpful?
                            </p>
                            <Rating
                                name="message-rating"
                                value={rating}
                                precision={1}
                                size="medium"
                                onChange={handleRatingChange}
                                sx={{
                                    '& .MuiRating-iconFilled': {
                                        color: 'lightgreen'
                                    },
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

function MessagesList( {messages} ) {
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {messageIsLoadingDetails, setMessageIsLoadingDetails} = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    const [secondsElapsed, setSecondsElapsed] = React.useState(0);
    const [lastSecondsElapsed, setLastSecondsElapsed] = React.useState(null);


    React.useEffect(() => {
        console.log('[MessagesList] messages: ', messages);
    }, [messages]);

    React.useEffect(() => {
        let interval;
        
        if (messageIsLoading) {
        // Start the interval to update time every 100ms
        interval = setInterval(() => {
            setSecondsElapsed(prev => prev + 0.1); // Increment by 0.1 every 100ms
        }, 100);
        } else {
            // If not processing, clear the timer
            setLastSecondsElapsed(secondsElapsed); // Save last time when processing stops
            setSecondsElapsed(0); // Reset timer when processing stops
        }

        // Clear interval when component unmounts or stops processing
        return () => clearInterval(interval);
    },  [messageIsLoading, currentChatId]);


    return (
        <div
            className='flex flex-col w-full h-full justify-start items-center overflow-y-auto mt-[50px] md:mt-0'
        >
            <div className="flex flex-col gap-5 w-full justify-start items-center mb-[90px]">
                <div className="h-full w-full overflow-x-hidden justify-center flex pt-28">
                    <div className="flex flex-col gap-10 w-11/12 sm:w-[58%] bg-yellow">
                        {messages.map((chat, index) => (
                            <ChatMessage key={index} role_id={chat.role_id} content={chat.content} lastSecondsElapsed={lastSecondsElapsed} messageId={chat.message_id} lastMessageId={messages[index - 1]?.message_id} reasoning={chat.reasoning} actionType={chat.action} actionValue={chat.action_value} />
                        ))}
                    </div>
                </div>
                { messageIsLoading &&
                <div
                    className="flex flex-row mt-8 justify-between items-center w-11/12 sm:w-[58%] mb-20"
                >
                    <div 
                        className="flex flex-row gap-4 justify-start items-center w-full px-2 sm:px-0"
                    >
                        <HelixLoader/>
                        <motion.div
                            key={messageIsLoadingDetails}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                            className='text-base md:text-base text-gray-700 text-justify md:leading-10'
                            >
                            <motion.p
                                key={messageIsLoadingDetails}
                                initial={{ opacity: 1 }}
                                animate={{ opacity: [1, 0.5, 1] }}
                                transition={{ duration: 3, repeat: Infinity }}
                                >
                                {messageIsLoadingDetails}
                            </motion.p>
                        </motion.div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

function NoMessages({ createChat }) {
    const { messageIsLoading } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);
    const { model, setModel } = React.useContext(UserContext);
    const [firstMessage, setFirstMessage] = React.useState('');
    const [textareaHeight, setTextareaHeight] = React.useState(50);
    const textareaRef = React.useRef(null);
    const [showApexBadge, setShowApexBadge] = React.useState(true);
    const [promptTipsOpen, setPromptTipsOpen] = React.useState(false);

    const [apexMessagesLeft, setApexMessagesLeft] = React.useState(null);
    const [showApexMessagesLeft, setShowApexMessagesLeft] = React.useState(true);

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/check-apex-messages-left?userId=${userId}`)
            .then(response => {
                const apexMessagesLeft = response.data.apex_messages_left;
                const maxApexMessages = response.data.max_apex_messages;
                console.log('Max apex messages:', maxApexMessages);
                console.log('Apex messages left:', apexMessagesLeft);
                if (maxApexMessages < 100) {
                    console.log('Max apex messages:', maxApexMessages);
                    setShowApexMessagesLeft(true);
                } else {
                    console.log('Showing apex messages left:', apexMessagesLeft);
                    setShowApexMessagesLeft(false);
                }
                setApexMessagesLeft(apexMessagesLeft);
            })
            .catch(error => {
                console.error('Error checking messages left:', error);
            });
    }, [userId]);

    const examplePrompts = [
        {
            icon: "📚",
            text: "Explain quantum entanglement in simple terms",
            category: "Science"
        },
        {
            icon: "💡",
            text: "Help me understand the implications of AI in healthcare",
            category: "Technology"
        },
        {
            icon: "🧬",
            text: "What are the latest developments in CRISPR research?",
            category: "Research"
        }
    ];

    const handleExampleClick = (prompt) => {
        setFirstMessage(prompt);
        textareaRef.current.focus();
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            await createChat({firstMessage: firstMessage, currentChatId: currentChatId});
        }
    };

    const handleApexClick = () => {
        setModel('apex');
        setShowApexBadge(false);
        confetti({
            particleCount: 100,
            spread: 70,
            colors: ['#f97316', '#facc15', '#fb923c'],
            origin: { y: 0.6 }
        });
    };

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            const newHeight = textareaRef.current.scrollHeight;
            if (newHeight > 125) {
                textareaRef.current.style.height = "125px";
                textareaRef.current.style.overflowY = "auto";
                setTextareaHeight(150);
            } else {
                textareaRef.current.style.height = `${newHeight}px`;
                textareaRef.current.style.overflowY = "hidden";
                setTextareaHeight(newHeight);
            }
        }
    }, [firstMessage]);

    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="first-step flex flex-col justify-center items-center rounded-lg gap-4 md:gap-8 p-2 md:p-4 min-h-[500px] w-full max-w-[700px] mx-auto"
        >
            <motion.div 
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col justify-center items-center gap-2 md:gap-4"
            >
                <div className="relative">
                    <img 
                        className='w-10 h-10 md:w-16 md:h-16 animate-pulse'
                        src={SparkAILogo} 
                        alt="SparkAI_logo"
                    />
                    <motion.div 
                        animate={{ 
                            scale: [1, 1.2, 1],
                            opacity: [0.5, 0.8, 0.5]
                        }}
                        transition={{ 
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                        className={`absolute -inset-2 ${model === 'apex' ? 'bg-orange-100' : 'bg-emerald-100'} rounded-full -z-10`}
                    />
                </div>
                <h1 className={`text-2xl md:text-3xl font-bold text-center bg-gradient-to-r ${
                    model === 'apex' 
                        ? 'from-orange-600 to-yellow-500'
                        : 'from-emerald-600 to-green-600'
                } bg-clip-text text-transparent px-4`}>
                    Ask anything, save time researching
                </h1>
            </motion.div>

            <div className="w-full max-w-2xl px-2 md:px-4">
                <form 
                    onSubmit={async (e) => {
                        e.preventDefault();
                        await createChat({firstMessage: firstMessage, currentChatId: currentChatId});
                    }}
                    className="w-full relative group"
                >
                    <motion.div
                        className="absolute inset-0 rounded-2xl bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-md transition-all duration-300"
                        animate={{
                            scale: [1, 1.02, 1],
                        }}
                        transition={{
                            duration: 4,
                            repeat: Infinity,
                        }}
                    />
                    <div className="relative bg-white rounded-2xl border border-gray-200 focus-within:border-emerald-500 hover:border-emerald-500/50 transition-all duration-300 shadow-sm">
                        <div className="px-4 pt-2.5 pb-1 flex items-center justify-between">
                            <div className="flex items-center gap-2 text-xs text-gray-500">
                                <div className="flex items-center gap-1.5">
                                    <div className="w-2 h-2 rounded-full bg-emerald-500" />
                                    <span className="font-medium">
                                        {model === 'apex' ? 'SparkAI APEX' : model === 'smart' ? 'SparkAI Pro' : 'SparkAI mini'}
                                    </span>
                                </div>
                                •
                                <span>Start typing</span>
                                {showApexMessagesLeft && model === 'apex' && (
                                    <span className="text-xs text-gray-500 ml-2">
                                        {apexMessagesLeft} apex messages left this month
                                    </span>
                                )}
                            </div>
                            
                            <Tooltip title="Get tips for better responses" placement="top" arrow>
                                <motion.button
                                    type="button"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => setPromptTipsOpen(true)}
                                    className="flex items-center gap-1.5 px-2.5 py-1.5 rounded-lg text-amber-500 hover:bg-amber-50 transition-colors"
                                >
                                    <span className="text-xs font-medium">Prompt Tips</span>
                                    <FaLightbulb className="w-3.5 h-3.5" />
                                </motion.button>
                            </Tooltip>
                        </div>

                        <div className="relative flex items-end">
                            <textarea
                                ref={textareaRef}
                                required
                                value={firstMessage}
                                onChange={(e) => setFirstMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                disabled={messageIsLoading}
                                placeholder={messageIsLoading ? "SparkAI is thinking..." : "Ask anything..."}
                                className="w-full pl-4 pr-16 py-3 outline-none rounded-2xl resize-none placeholder:text-gray-400"
                                style={{
                                    minHeight: '56px',
                                    maxHeight: '200px'
                                }}
                            />
                            
                            <div className="absolute right-2 bottom-2.5 flex items-center gap-2">
                                {firstMessage.length > 0 && (
                                    <motion.div 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className="text-xs text-gray-400"
                                    >
                                        {firstMessage.length}/4000
                                    </motion.div>
                                )}
                                
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    type="submit"
                                    disabled={messageIsLoading}
                                    className={`relative flex-shrink-0 p-2 rounded-xl transition-all duration-300 ${
                                        messageIsLoading
                                            ? 'bg-gray-100 text-gray-400'
                                            : 'bg-gradient-to-r from-emerald-500 to-green-500 text-white shadow-sm hover:shadow-md hover:shadow-emerald-500/20'
                                    }`}
                                >
                                    {messageIsLoading ? (
                                        <motion.div
                                            animate={{ rotate: 360 }}
                                            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                                        >
                                            <CircularProgress size="sm" thickness={2} />
                                        </motion.div>
                                    ) : (
                                        <IoSend className="w-4 h-4" />
                                    )}
                                </motion.button>
                            </div>
                        </div>
                    </div>
                </form>

                {showApexBadge && (
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="flex justify-center w-full mt-3"
                    >
                        <motion.button
                            onClick={handleApexClick}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="group flex items-center gap-2 px-3 md:px-4 py-2 bg-gradient-to-r from-orange-50 via-yellow-100 to-orange-50 hover:from-orange-100 hover:via-yellow-200 hover:to-orange-100 rounded-full shadow-sm hover:shadow-md transition-all duration-300 border border-orange-200 text-sm md:text-base w-full md:w-auto justify-center"
                        >
                            <span className="text-xs text-orange-500 font-mono border border-orange-300 px-2 py-0.5 rounded">NEW</span>
                            <span className="font-semibold bg-gradient-to-r from-orange-600 to-yellow-500 bg-clip-text text-transparent">Try APEX Model for enhanced reasoning</span>
                            <motion.span
                                animate={{ x: [0, 5, 0] }}
                                transition={{ duration: 1.5, repeat: Infinity }}
                                className="text-orange-400 group-hover:text-orange-500"
                            >
                                ⚡
                            </motion.span>
                        </motion.button>
                    </motion.div>
                )}
            </div>

            <div className="w-full max-w-2xl px-2 md:px-4">
                <p className="text-sm text-gray-500 mb-2 md:mb-4 text-center">Try these examples:</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
                    {examplePrompts.map((prompt, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="cursor-pointer p-3 md:p-4 bg-white rounded-xl border border-gray-200 hover:border-emerald-300 hover:shadow-md transition-all duration-200 h-[100px] md:h-[120px] flex flex-col justify-between"
                            onClick={() => handleExampleClick(prompt.text)}
                        >
                            <div className="flex items-center gap-2 md:gap-3">
                                <span className="text-xl md:text-2xl">{prompt.icon}</span>
                                <span className="text-[10px] md:text-xs font-medium text-emerald-600">{prompt.category}</span>
                            </div>
                            <p className="mt-1 md:mt-2 text-xs md:text-sm text-gray-700">{prompt.text}</p>
                        </motion.div>
                    ))}
                </div>
            </div>

            <AnimatePresence>
                <PromptTipsModal 
                    isOpen={promptTipsOpen} 
                    onClose={() => setPromptTipsOpen(false)} 
                />
            </AnimatePresence>
        </motion.div>
    );
}

export default function ChatBot( ) {
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { currentChatName, setCurrentChatName } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);
    const { setEnableChatInput } = React.useContext(UserContext);

    const { model } = React.useContext(UserContext);


    React.useEffect(() => {
        let interval;
      
        interval = setInterval(() => {
            console.log('Checking... Messages length:', messages.length);
        }, 3000); // Run every 5 seconds
      
        return () => clearInterval(interval); // Clear the interval when the component unmounts
      }, [messages, currentChatId]);

    const createChat = async ({ firstMessage }) => {
        
        if (messageIsLoading){
            return; // Prevent multiple submissions
        }

        // Validate message length
        if (calcMaxInputLength(model, firstMessage.length)) {
            alert(`Your message is too long. Please try again with a shorter message. Length: ${firstMessage.length}`);
            return;
        }

    
        try {
            // Step 1: Generate chat name
            const generatedChatNameResult = await createChatName(firstMessage);
            console.log('[CreateChat] Generated chat name:', generatedChatNameResult);
            const generatedChatName = generatedChatNameResult.chat_name;
            setCurrentChatName(generatedChatName);
            
            // Step 2: Update messages with the first message
            setMessages([{ role_id: 1, content: firstMessage }]);
            setMessageIsLoading(true); // Show loading state
    
            // Step 3: Create a new conversation
            const createConversationResult = await createConversation(userId, generatedChatName);
            console.log('[CreateChat] Created conversation:', createConversationResult);
            console.log('[CreateChat] Messages for conversation:', messages);
            const chatId = createConversationResult.chatId;
            console.log('[CreateChat] Chat ID:', chatId);
            setCurrentChatId(chatId);
    
            // Step 4: Make the SparkAI call
            const sparkaiCallResult = await SparkAICall(firstMessage, [{ role_id: 1, content: firstMessage }], model, chatId);
            console.log('[CreateChat] SparkAI call result:', sparkaiCallResult);

            if (sparkaiCallResult.error){
                console.log('[CreateChat] 1. SparkAI call error:', sparkaiCallResult.error);
                return;
            }
    
            // Update messages with SparkAI response
            setMessages(prevMessages => [
                ...prevMessages,
                { role_id: 2, content: sparkaiCallResult.text },
            ]);
        } catch (error) {
            console.error('[CreateChat] Error:', error);
            alert('An error occurred while processing your request. Please try again.');
        } finally {
            setMessageIsLoading(false); // Reset loading state
        }
    };
    

    const messagesLength = messages.length;
    React.useEffect(() => {

        if (messagesLength > 0) {
            setEnableChatInput(true);
        } else {
            setEnableChatInput(false);
        }
    }, [messages]);

    React.useEffect(() => {
        if (messageIsLoading) {
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then((response) => {
                console.log('[ChatBot] response: ', response);
                setMessages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId, messageIsLoading]);

    /**
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (currentChatId) {
                axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
                    .then((response) => {
                        console.log('[ChatBot] response: ', response);
                        if (response.data.length > messages.length) {
                            setMessages(response.data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching messages:', error);
                    });
            }
        }, 5000); // 5 seconds

        return () => clearInterval(interval);
    }, [currentChatId]);
    */
    

    return (
        <>
        {messagesLength > 0 || messageIsLoading ? <MessagesList messages={messages} /> : <NoMessages createChat={createChat}/>}
        </>
    );
}