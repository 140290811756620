import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { IoSearch, IoMdAdd } from "react-icons/io";
import { IoShieldCheckmark } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import { FaQuestionCircle } from "react-icons/fa";
import { BiSolidErrorAlt } from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import ChatList from './ChatButton';
import Tooltip from '@mui/joy/Tooltip';
import {ProfileMenu} from "./PersistentDrawer";
import SparkaiLogoBlack from "../assets/sparkai_logo_blackwhite.png";
import FocusLogoBlack from "../assets/focus_logo_black.png";
import { UserContext } from "../pages/Chat";
import { MainUserContext } from "../App";
import { useNavigate } from 'react-router-dom';
import HelpCenterModal from './HelpCenterModal';
import FeedbackModal from './FeedbackModal';
import BugReportModal from './BugReportModal';
import { FaHome } from 'react-icons/fa';
import { HiSparkles } from 'react-icons/hi';

export default function LambdaSidebar({ sidebarOpen, setSidebarOpen }) {
    const { setCurrentChatName, setMessages, setCurrentChatId } = React.useContext(UserContext);
    const { user } = React.useContext(MainUserContext);
    const [mail, setMail] = React.useState('error loading mail');
    const navigate = useNavigate();
    const [helpModalOpen, setHelpModalOpen] = React.useState(false);
    const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
    const [bugReportModalOpen, setBugReportModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (user) setMail(user.mail);
    }, [user]);

    return (
        <AnimatePresence>
            <motion.div
                initial={false}
                animate={{
                    width: sidebarOpen ? 300 : 70,
                    transition: { duration: 0.3, ease: "easeInOut" }
                }}
                className="flex flex-col h-screen fixed top-0 left-0 bg-white z-50"
            >
                {/* Main sidebar content with enhanced shadow */}
                <div className="h-full flex flex-col border-r border-gray-200 shadow-[2px_0_8px_-2px_rgba(0,0,0,0.05)]">
                    {/* Header section with dynamic toggle button alignment */}
                    <div className="flex flex-col p-3 gap-2">
                        <div className={`flex ${sidebarOpen ? 'justify-end' : 'justify-center'}`}>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                                className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-50 hover:bg-gray-100 transition-colors"
                            >
                                <motion.div
                                    initial={false}
                                    animate={{ rotate: sidebarOpen ? 0 : 180 }}
                                    transition={{ duration: 0.3, ease: "easeInOut" }}
                                >
                                    <HiChevronLeft className="w-5 h-5 text-gray-600" />
                                </motion.div>
                            </motion.button>
                        </div>

                        <AnimatePresence>
                            {sidebarOpen && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="flex flex-col gap-1"
                                >
                                    {/* Home button */}
                                    <motion.button 
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        onClick={() => navigate('/home')}
                                        className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 transition-all group"
                                    >
                                        <div className="relative">
                                            <FaHome className="w-4 h-4 text-gray-700" />
                                            <motion.div 
                                                className="absolute -inset-1 bg-emerald-100 rounded-full -z-10 opacity-0 group-hover:opacity-100"
                                                animate={{ scale: [1, 1.2, 1] }}
                                                transition={{ duration: 2, repeat: Infinity }}
                                            />
                                        </div>
                                        <span className="text-sm font-medium text-gray-700">Home</span>
                                    </motion.button>

                                    {/* Chat button */}
                                    <motion.button 
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        onClick={() => navigate('/chat')}
                                        className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 transition-all group"
                                    >
                                        <div className="relative">
                                            <HiSparkles className="w-4 h-4 text-gray-700" />
                                            <motion.div 
                                                className="absolute -inset-1 bg-blue-100 rounded-full -z-10 opacity-0 group-hover:opacity-100"
                                                animate={{ scale: [1, 1.2, 1] }}
                                                transition={{ duration: 2, repeat: Infinity }}
                                            />
                                        </div>
                                        <span className="text-sm font-medium text-gray-700">Chat</span>
                                    </motion.button>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        {/* New chat button */}
                        <motion.button 
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={() => {
                                if (!sidebarOpen) {
                                    setSidebarOpen(true);
                                } else {
                                    setSidebarOpen(false);
                                }
                                setCurrentChatName('');
                                setCurrentChatId(null);
                                setMessages([]);
                                navigate('/chat');
                            }}
                            className={`flex items-center gap-3 p-3 rounded-lg bg-gradient-to-r from-emerald-50 to-green-50 hover:from-emerald-100 hover:to-green-100 transition-all ${
                                !sidebarOpen ? 'justify-center' : ''
                            }`}
                        >
                            <IoMdAdd className="w-5 h-5 text-emerald-600" />
                            {sidebarOpen && (
                                <span className="text-sm font-medium text-emerald-600">New Chat</span>
                            )}
                        </motion.button>
                    </div>

                    {/* Chat section with proper structure */}
                    <div className="flex-1 flex flex-col min-h-0">
                        <div className="flex-1 overflow-y-auto">
                            <div className="px-3 py-2">
                                <ChatList
                                    hidden={!sidebarOpen}
                                    setSidebarOpen={setSidebarOpen}
                                    filterName="" 
                                    setCurrentChatName={setCurrentChatName}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Footer section with profile */}
                    <motion.div 
                        className="border-t border-gray-100 p-3 space-y-2 bg-gray-50/50"
                        initial={false}
                        animate={{
                            x: sidebarOpen ? 0 : 0,
                            opacity: sidebarOpen ? 1 : 0.5
                        }}
                    >
                        {/* Account info with centered alignment when closed */}
                        <div className={`flex items-center gap-3 ${!sidebarOpen ? 'justify-center' : 'mb-5'}`}>
                            <ProfileMenu setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
                            {sidebarOpen && (
                                <motion.div
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    className="flex flex-col"
                                >
                                    <span className="text-sm font-medium text-gray-700 truncate">
                                        {mail}
                                    </span>
                                    <div className="flex items-center gap-1">
                                        <IoShieldCheckmark className="w-3 h-3 text-emerald-500" />
                                        <span className="text-xs text-emerald-600">Active Account (Beta)</span>
                                    </div>
                                </motion.div>
                            )}
                        </div>

                        {/* Enhanced Feedback Section */}
                        <div className={`${sidebarOpen ? 'flex justify-between px-2' : 'hidden'} items-center gap-3`}>
                            {/* Feedback Button */}
                            <Tooltip 
                                title={sidebarOpen ? "Share your thoughts!" : "Feedback"} 
                                placement="top"
                                arrow
                            >
                                <motion.button 
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => setFeedbackModalOpen(true)}
                                    className="relative group"
                                >
                                    <div className="p-2 rounded-xl bg-gradient-to-r from-pink-50 to-rose-50 hover:from-pink-100 hover:to-rose-100 transition-all">
                                        <div className="relative">
                                            <FaHeart className="w-4 h-4 text-pink-400 group-hover:text-pink-500 transition-all" />
                                            <motion.div
                                                className="absolute -inset-1 bg-pink-100 rounded-full -z-10 opacity-0 group-hover:opacity-100"
                                                animate={{ scale: [1, 1.2, 1] }}
                                                transition={{ duration: 2, repeat: Infinity }}
                                            />
                                        </div>
                                    </div>
                                    {sidebarOpen && (
                                        <motion.span 
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center"
                                        >
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-200 opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-pink-400"></span>
                                        </motion.span>
                                    )}
                                </motion.button>
                            </Tooltip>

                            {/* Support/Bug Report Button */}
                            <Tooltip 
                                title={sidebarOpen ? "Report an issue" : "Support"} 
                                placement="top"
                                arrow
                            >
                                <motion.button 
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => setBugReportModalOpen(true)}
                                    className="relative group"
                                >
                                    <div className="p-2 rounded-xl bg-gradient-to-r from-amber-50 to-orange-50 hover:from-amber-100 hover:to-orange-100 transition-all">
                                        <div className="relative">
                                            <BiSolidErrorAlt className="w-4 h-4 text-amber-400 group-hover:text-amber-500 transition-all" />
                                            <motion.div
                                                className="absolute -inset-1 bg-amber-100 rounded-full -z-10 opacity-0 group-hover:opacity-100"
                                                animate={{ scale: [1, 1.2, 1] }}
                                                transition={{ duration: 2, repeat: Infinity, delay: 0.5 }}
                                            />
                                        </div>
                                    </div>
                                </motion.button>
                            </Tooltip>

                            {/* Help/Documentation Button - New Addition */}
                            {sidebarOpen && (
                                <Tooltip title="Help Center" placement="top" arrow>
                                    <motion.button 
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={() => setHelpModalOpen(true)}
                                        className="relative group"
                                    >
                                        <div className="p-2 rounded-xl bg-gradient-to-r from-blue-50 to-indigo-50 hover:from-blue-100 hover:to-indigo-100 transition-all">
                                            <div className="relative">
                                                <FaQuestionCircle className="w-4 h-4 text-blue-400 group-hover:text-blue-500 transition-all" />
                                                <motion.div
                                                    className="absolute -inset-1 bg-blue-100 rounded-full -z-10 opacity-0 group-hover:opacity-100"
                                                    animate={{ scale: [1, 1.2, 1] }}
                                                    transition={{ duration: 2, repeat: Infinity, delay: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </motion.button>
                                </Tooltip>
                            )}
                        </div>
                    </motion.div>
                </div>

                {/* Backdrop overlay when sidebar is open on mobile */}
                {sidebarOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-[-1] lg:hidden"
                        onClick={() => setSidebarOpen(false)}
                    />
                )}

                <HelpCenterModal 
                    isOpen={helpModalOpen} 
                    onClose={() => setHelpModalOpen(false)} 
                />
                <FeedbackModal 
                    isOpen={feedbackModalOpen} 
                    onClose={() => setFeedbackModalOpen(false)} 
                />
                <BugReportModal 
                    isOpen={bugReportModalOpen} 
                    onClose={() => setBugReportModalOpen(false)} 
                />
            </motion.div>
        </AnimatePresence>
    );
}