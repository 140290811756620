import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose, IoMail } from 'react-icons/io5';
import { FaHeadset, FaArrowRight } from 'react-icons/fa6';

export default function HelpCenterModal({ isOpen, onClose }) {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center p-4"
                    onClick={onClose}
                >
                    <motion.div
                        initial={{ scale: 0.95, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.95, opacity: 0 }}
                        onClick={(e) => e.stopPropagation()}
                        className="relative w-full max-w-lg bg-white rounded-2xl shadow-xl"
                    >
                        {/* Close button */}
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onClose}
                            className="absolute top-4 right-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
                        >
                            <IoClose className="w-5 h-5 text-gray-500" />
                        </motion.button>

                        {/* Content */}
                        <div className="p-6">
                            {/* Header with gradient background */}
                            <div className="relative rounded-xl bg-gradient-to-r from-emerald-500 to-green-500 p-5 mb-6 overflow-hidden">
                                <motion.div
                                    className="absolute inset-0 bg-white opacity-10"
                                    animate={{
                                        scale: [1, 1.2, 1],
                                        opacity: [0.1, 0.15, 0.1],
                                    }}
                                    transition={{
                                        duration: 4,
                                        repeat: Infinity,
                                    }}
                                />
                                <div className="relative flex items-center gap-4">
                                    <div className="p-3 bg-white/20 rounded-lg backdrop-blur-sm">
                                        <FaHeadset className="w-6 h-6 text-white" />
                                    </div>
                                    <div>
                                        <h2 className="text-xl font-semibold text-white">Need Help?</h2>
                                        <p className="text-emerald-100">We're here to assist you</p>
                                    </div>
                                </div>
                            </div>

                            {/* Support description */}
                            <div className="mb-6">
                                <p className="text-gray-600">
                                    Our support team is ready to help you with any questions or issues you might have. 
                                    Get in touch with us via email for personalized assistance.
                                </p>
                            </div>

                            {/* Email contact card */}
                            <motion.a
                                href="mailto:support@ederspark.com"
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="block group"
                            >
                                <div className="relative overflow-hidden rounded-xl border border-emerald-100 bg-gradient-to-r from-emerald-50 to-green-50 p-4">
                                    <motion.div
                                        className="absolute inset-0 bg-gradient-to-r from-emerald-500/10 via-green-500/10 to-emerald-500/10"
                                        animate={{
                                            x: ['0%', '100%', '0%'],
                                        }}
                                        transition={{
                                            duration: 3,
                                            ease: "linear",
                                            repeat: Infinity,
                                        }}
                                    />
                                    <div className="relative flex items-center justify-between">
                                        <div className="flex items-center gap-3">
                                            <div className="p-2 bg-emerald-100 rounded-lg">
                                                <IoMail className="w-5 h-5 text-emerald-600" />
                                            </div>
                                            <div>
                                                <p className="font-medium text-gray-800">Email Support</p>
                                                <p className="text-sm text-emerald-600">support@ederspark.com</p>
                                            </div>
                                        </div>
                                        <FaArrowRight className="w-5 h-5 text-emerald-500 transform group-hover:translate-x-1 transition-transform" />
                                    </div>
                                </div>
                            </motion.a>

                            {/* Response time indicator */}
                            <div className="mt-6 flex items-center justify-center gap-2 text-sm text-gray-500">
                                <div className="w-2 h-2 rounded-full bg-emerald-500 animate-pulse" />
                                <span>Average response time: 1 hour</span>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
} 