import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HiSparkles } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";

export default function ComingSoonModal({ isOpen, onClose, featureName = "This feature" }) {
    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
                onClick={onClose}
            >
                <motion.div
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.95, opacity: 0 }}
                    onClick={(e) => e.stopPropagation()}
                    className="bg-white rounded-2xl shadow-xl w-full max-w-md p-6 relative"
                >
                    {/* Close button */}
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
                    >
                        <IoClose className="w-6 h-6" />
                    </button>

                    {/* Content */}
                    <div className="flex flex-col items-center text-center gap-4">
                        <div className="relative">
                            <HiSparkles className="w-12 h-12 text-emerald-500" />
                            <motion.div
                                className="absolute -inset-4 bg-emerald-100 rounded-full -z-10"
                                animate={{ 
                                    scale: [1, 1.2, 1],
                                    opacity: [0.5, 0.8, 0.5]
                                }}
                                transition={{ 
                                    duration: 2,
                                    repeat: Infinity,
                                    ease: "easeInOut"
                                }}
                            />
                        </div>

                        <h2 className="text-2xl font-bold text-gray-800">Coming Soon!</h2>
                        
                        <p className="text-gray-600">
                            {featureName} is currently under development. We're working hard to bring you something amazing!
                        </p>

                        <div className="bg-emerald-50 rounded-xl p-4 mt-2">
                            <p className="text-sm text-emerald-600">
                                Stay tuned for updates! We'll notify you when this feature becomes available.
                            </p>
                        </div>

                        <button
                            onClick={onClose}
                            className="mt-4 px-6 py-2.5 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-xl hover:shadow-lg hover:shadow-emerald-500/20 transition-all duration-200"
                        >
                            Got it!
                        </button>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
} 