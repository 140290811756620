import React from "react";
import { UserContext } from "../pages/Focus";
import TopHeader from "./TopHeader";
import NoPaper from "./NoPaper";
import PDFViewer from "./PDFViewer";
import FloatingChatBot from "./FloatingChatBot";

export default function FocusContent() {
    const { isOpen, setIsOpen, currentChatName, paper, currentChatId } = React.useContext(UserContext);
    const [ marginLeft, setMarginLeft ] = React.useState(300);

    React.useEffect(() => {
        if (isOpen) {
            setMarginLeft(300);
        } else {
            setMarginLeft(70); // Fixed value for collapsed sidebar
        }
    }, [isOpen]);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="flex flex-col h-screen w-full">
            <TopHeader 
                isOpen={isOpen} 
                toggleDrawer={toggleDrawer} 
                currentChatName={currentChatName} 
                marginLeft={marginLeft}
            />
            <main 
                className='h-full w-full'
                style={{
                    marginLeft: marginLeft,
                    transition: 'margin-left 0.3s ease-in-out',
                    width: `calc(100vw - ${marginLeft}px)`
                }}
            >
                {paper === null ? <NoPaper/> : 
                    <PDFViewer url={paper.url} onError={() => (
                        <div className="flex justify-center items-center h-screen">
                            <p className="text-gray-500">We are sorry, this paper can't be displayed</p>
                        </div>
                    )} />
                }
                {currentChatId != null && <FloatingChatBot />}
            </main>
        </div>
    );
} 