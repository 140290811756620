import React from 'react';
import { motion } from 'framer-motion';
import { IoArrowBack, IoCalendarOutline, IoTimeOutline, IoBookmarkOutline } from 'react-icons/io5';
import { FaXTwitter, FaLinkedin } from 'react-icons/fa6';

const article = {
    badge: "NEW",
    title: "Introducing APEX Model: A Breakthrough for Research",
    description: "Experience revolutionary research capabilities with our latest APEX model. Designed specifically for academic and scientific work, delivering unprecedented depth and accuracy in research assistance.",
    date: "January 30, 2025",
    link: "#",
    image: "",
    readTime: "<1 min",
    content: [
        {
            heading: "Revolutionizing Research with AI",
            text: "Today marks a watershed moment as we unveil APEX, our most sophisticated AI model engineered specifically for research excellence. APEX sets a new standard in artificial intelligence for academic work, combining unparalleled research capabilities with deep contextual understanding and advanced scientific reasoning."
        },
        {
            heading: "Research-Focused Features",
            list: [
                "State-of-the-art academic paper analysis and synthesis",
                "Deep comprehension of scientific methodologies and research frameworks",
                "Advanced citation management and reference tracking",
                "Sophisticated data analysis and interpretation capabilities",
                "Enhanced understanding of field-specific terminology and concepts"
            ]
        },
        {
            heading: "Research Applications",
            text: "APEX shines brightest in research-intensive scenarios, revolutionizing how scholars approach literature reviews, methodology design, and data analysis. Its advanced capabilities make it an invaluable research assistant, capable of processing and synthesizing vast amounts of academic literature while maintaining rigorous scientific standards."
        },
        {
            heading: "Research Performance Metrics",
            text: "Our rigorous testing reveals that APEX achieves a remarkable 40% improvement in research-related tasks compared to previous models. This includes enhanced accuracy in literature analysis, methodology evaluation, and scientific reasoning, making it particularly valuable for academic research and scientific investigation."
        },
        {
            heading: "Empowering Researchers",
            text: "APEX is now available to all SparkAI users, ready to transform your research workflow. Whether you're conducting literature reviews, analyzing research methodologies, or exploring new scientific hypotheses, APEX provides unprecedented support for academic and scientific endeavors. Access this powerful research assistant by selecting APEX as your model in the interface."
        }
    ]
}

export default function NewsArticle() {
    const onBack = () => {
        window.location.href = "/chat";
    }
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-gradient-to-b from-white to-emerald-50/30"
        >
            {/* Header */}
            <header className="sticky top-0 z-50 backdrop-blur-xl bg-white/80 border-b border-emerald-100">
                <div className="max-w-4xl mx-auto px-4 py-4">
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={onBack}
                        className="flex items-center gap-2 px-3 py-1.5 rounded-full text-emerald-600 hover:bg-emerald-50 transition-colors"
                    >
                        <IoArrowBack className="w-4 h-4" />
                        <span className="text-sm font-medium">Back to SparkAI</span>
                    </motion.button>
                </div>
            </header>

            {/* Main Content */}
            <main className="max-w-4xl mx-auto px-4 py-8">
                {/* Article Header */}
                <div className="mb-8">
                    <div className="flex items-center gap-2 mb-4">
                        <span className="px-2.5 py-1 text-xs font-semibold text-emerald-600 bg-emerald-100 rounded-full">
                            {article.badge}
                        </span>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                            <IoCalendarOutline className="w-4 h-4" />
                            <span>{article.date}</span>
                            <span className="w-1 h-1 rounded-full bg-gray-300" />
                            <IoTimeOutline className="w-4 h-4" />
                            <span>{article.readTime} read</span>
                        </div>
                    </div>
                    
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        {article.title}
                    </h1>
                    
                    <p className="text-lg text-gray-600 mb-6">
                        {article.description}
                    </p>

                    {/* Share and Save buttons */}
                    <div className="flex items-center gap-4">
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-colors"
                        >
                            <IoBookmarkOutline className="w-5 h-5" />
                            <span>Save for later</span>
                        </motion.button>
                        <div className="flex items-center gap-2">
                            <motion.button
                                onClick={() => {
                                    window.open(`https://x.com/EderSpark`, '_blank');
                                }}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-600 transition-colors"
                            >
                                <FaXTwitter className="w-5 h-5" />
                            </motion.button>
                            <motion.button
                                onClick={() => {
                                    window.open(`https://www.linkedin.com/company/ederspark`, '_blank');
                                }}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-600 transition-colors"
                            >
                                <FaLinkedin className="w-5 h-5" />
                            </motion.button>
                        </div>
                    </div>
                </div>

                {/* Article Content */}
                <div className="prose prose-emerald max-w-none">
                    {article.image && (
                        <div className="relative rounded-2xl overflow-hidden mb-8">
                            <img 
                                src={article.image} 
                                alt={article.title}
                                className="w-full h-[400px] object-cover"
                                onError={(e) => e.target.parentElement.style.display = 'none'}
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
                        </div>
                    )}

                    {/* Rich Text Content */}
                    <div className="space-y-6">
                        {article.content.map((section, index) => (
                            <div key={index} className="space-y-4">
                                {section.heading && (
                                    <h2 className="text-2xl font-bold text-gray-900">
                                        {section.heading}
                                    </h2>
                                )}
                                {section.text && (
                                    <p className="text-gray-600 leading-relaxed">
                                        {section.text}
                                    </p>
                                )}
                                {section.list && (
                                    <ul className="list-disc list-inside space-y-2 text-gray-600">
                                        {section.list.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Call to Action */}
                    <div className="mt-12">
                        <motion.div
                            whileHover={{ scale: 1.01 }}
                            className="p-6 rounded-2xl bg-gradient-to-r from-emerald-50 to-green-50 border border-emerald-100"
                        >
                            <h3 className="text-xl font-semibold text-gray-900 mb-2">
                                Ready to try these new features?
                            </h3>
                            <p className="text-gray-600 mb-4">
                                Start exploring the latest updates and enhance your SparkAI experience today.
                            </p>
                            <motion.button
                                onClick={() => {
                                    window.location.href = "/chat";
                                }}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="px-6 py-2.5 rounded-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-colors"
                            >
                                Get Started
                            </motion.button>
                        </motion.div>
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer className="border-t border-emerald-100 mt-16">
                <div className="max-w-4xl mx-auto px-4 py-8">
                    <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-500">
                            © 2024 SparkAI. All rights reserved.
                        </span>
                        <div className="flex items-center gap-4">
                            <a href="#" className="text-sm text-gray-500 hover:text-emerald-600">
                                Terms
                            </a>
                            <a href="#" className="text-sm text-gray-500 hover:text-emerald-600">
                                Privacy
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </motion.div>
    );
} 