import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Button,
    Modal,
    ModalClose,
    Typography,
    Sheet,
    AccordionGroup,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    Badge,
} from '@mui/joy';
import { FaFire } from "react-icons/fa6";
import { FireIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { UserContext } from '../pages/Chat';
import { MainUserContext } from '../App';

// Create a focused chat
export const createFocusChat = async (chatDetails, blank = true) => {
    const checkAuthorsType = (authors) => {
        if (typeof authors === 'string') {
            return authors;
        } else {
            return authors.map(author => author.name).join(', ');
        }
    }
    try {
        console.log('Creating focus chat with details:', chatDetails);
        // Check if the authors is an array
        const authors = checkAuthorsType(chatDetails.authors);
        console.log('Authors:', authors);
        
        const newChatDetails = {
            ...chatDetails,
            authors: authors
        }
        console.log('New chat details:', newChatDetails);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/focus/chat/create`, newChatDetails);
        
        console.log('Response from focus chat create:', response.data);
        if (blank) {
            console.log('Opening in blank');
            window.open(`/focus/?chat_id=${response.data.conversation_id}`, '_blank');
        } else {
            console.log('Opening in self');
            window.open(`/focus/?chat_id=${response.data.conversation_id}`, '_self');
        }
    } catch (error) {
        console.error('Error creating focus chat:', error.message || error);
    }
};

function SourceComponent({ source }) {
    const { userId } = useContext(MainUserContext);
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    // Fetch the message associated with the source
    useEffect(() => {
        const fetchMessage = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sources/messages/${source.source_id}`);
                if (response.data.status === 'success') {
                    setMessage(response.data.message);
                } else {
                    console.warn('Error fetching message:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching message:', error.message || error);
            }
        };

        fetchMessage();
    }, [source.source_id]);

    // Create a focused chat
    const createFocusChat = async (chatDetails) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/focus/chat/create`, chatDetails);
            window.open(`/focus/?chat_id=${response.data.conversation_id}`, '_blank');
        } catch (error) {
            console.error('Error creating focus chat:', error.message || error);
        }
    };

    return (
        <Accordion>
            <AccordionSummary>
                <div className="flex flex-col w-full gap-2">
                    <div className="flex flex-row justify-between w-full gap-4">
                        <Typography variant="h6">{source.title}</Typography>
                    </div>
                    <p
                        className="text-gray-700 font-semibold text-sm"
                    >
                        {source.authors.replace(/"/g, '')}
                    </p>
                    {message && (
                        <div className="flex flex-row gap-2 items-center border-2 border-gray-200 rounded-lg px-3 py-1 w-11/12">
                            <QuestionMarkCircleIcon className="w-5 h-5 text-emerald-500" />
                            <Typography variant="body2" color="text.secondary">
                                {message.content}
                            </Typography>
                        </div>
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <p
                    className="text-gray-800 text-sm text-justify max-h-44 overflow-y-auto px-2"
                >
                    {source.abstract || "No abstract available, sorry for the inconvenience!"}
                </p>
                <Stack direction="row" spacing={2} mt={2}>
                    <button
                        className="bg-white border-2 border-emerald-300 text-emerald-500 hover:bg-emerald-100 rounded-md px-4"
                        onClick={() => window.open(source.url, '_blank')}
                    >
                        Open PDF
                    </button>
                    <button
                        className="bg-emerald-500 text-white hover:bg-emerald-400 rounded-md px-4"
                        onClick={() =>
                            createFocusChat({
                                conversation_name: source.title,
                                user_id: userId,
                                paper_id: source.paper_id,
                                title: source.title,
                                authors: source.authors,
                                abstract: source.abstract,
                                url: source.url,
                            })
                        }
                    >
                        Open in Focus
                    </button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export default function SourcesList({ width, messageId, thisMessageSelected, chatId, isOpen }) {
    const { sources, setSources } = useContext(UserContext);
    const [messageSources, setMessageSources] = useState([]);
    const [chatSources, setChatSources] = useState([]);

    // Helper function to sort sources by authors alphabetically
    const sortSourcesByAuthors = (sources) => {
        return sources.sort((a, b) => {
            if (a.authors && b.authors) {
                return a.authors.localeCompare(b.authors);
            }
            return 0; // Keep order if authors are missing
        });
    };

    // Fetch chat sources
    useEffect(() => {
        if (!chatId) {
            console.warn("Chat ID is undefined. Skipping source retrieval.");
            return;
        }

        console.log("Fetching chat sources for chat ID:", chatId, "and message ID:", messageId);

        const fetchChatSources = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sources/chat/${chatId}`);
                console.log("Chat sources response:", response.data);
                const sortedSources = response.data.status === 'success' 
                    ? sortSourcesByAuthors(response.data.sources || []) 
                    : [];
                setChatSources(sortedSources);
            } catch (error) {
                console.error('Error fetching chat sources:', error.message || error);
                setChatSources([]);
            }
        };

        fetchChatSources();
    }, [chatId, isOpen, thisMessageSelected]);

    // Fetch message sources
    useEffect(() => {
        if (!thisMessageSelected || messageId === null) {
            setMessageSources([]);
            return;
        }

        console.log("Fetching message sources for chat ID:", chatId, "and message ID:", messageId);

        const fetchMessageSources = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sources/message/${messageId}`);
                console.log("Message sources response:", response.data);
                const sortedSources = response.data.status === 'success' 
                    ? sortSourcesByAuthors(response.data.sources || []) 
                    : [];
                setMessageSources(sortedSources);
            } catch (error) {
                console.error('Error fetching message sources:', error.message || error);
                setMessageSources([]);
            }
        };

        fetchMessageSources();
    }, [messageId, thisMessageSelected, isOpen]);

    // Set sources based on the selected message or chat
    useEffect(() => {
        setSources(thisMessageSelected ? messageSources : chatSources);
    }, [thisMessageSelected, messageSources, chatSources, setSources]);

    return (
        <div className="flex flex-col w-full h-full gap-4 overflow-y-auto bg-white">
            {messageId === null ? (
                <Typography variant="h6" color="text.secondary">
                    No message selected
                </Typography>
            ) : sources.length === 0 ? (
                <Typography variant="h6" color="text.secondary">
                    No sources available
                </Typography>
            ) : (
                <AccordionGroup sx={{ width }}>
                    {sources.map((source, index) => (
                        <SourceComponent key={index} source={source} />
                    ))}
                </AccordionGroup>
            )}
        </div>
    );
}
