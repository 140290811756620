import React from 'react';
import { motion } from 'framer-motion';
import { FaLightbulb } from "react-icons/fa6";

export default function PromptTipsModal({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center p-4"
            onClick={onClose}
        >
            <motion.div
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.95, opacity: 0 }}
                onClick={(e) => e.stopPropagation()}
                className="bg-white rounded-2xl shadow-xl max-w-2xl w-full max-h-[80vh] overflow-hidden"
            >
                <div className="p-6 border-b border-gray-100">
                    <div className="flex items-center gap-3">
                        <div className="p-2 rounded-xl bg-gradient-to-r from-amber-50 to-orange-50">
                            <FaLightbulb className="w-5 h-5 text-amber-500" />
                        </div>
                        <h2 className="text-xl font-semibold text-gray-800">Prompt Tips</h2>
                    </div>
                </div>

                <div className="p-6 overflow-y-auto text-[0.78rem]">
                    <div className="space-y-6">
                        <div className="flex gap-3">
                            <div className="flex-shrink-0 w-16 h-6 rounded-full bg-emerald-100 text-emerald-600 font-medium flex items-center justify-center">
                                Do ✓
                            </div>
                            <div>
                                <h3 className="font-medium text-gray-900 mb-2">Be Specific</h3>
                                <p className="text-gray-600">SparkAI excels at handling detailed, complex questions. The more specific your prompt, the more accurate and valuable the response will be.</p>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="flex-shrink-0 w-16 h-6 rounded-full bg-emerald-100 text-emerald-600 font-medium flex items-center justify-center">
                                Do ✓
                            </div>
                            <div>
                                <h3 className="font-medium text-gray-900 mb-2">Structure Your Request</h3>
                                <p className="text-gray-600">Use phrases like "Write a detailed explanation", "Provide a comprehensive analysis", or "Give step-by-step instructions" to get thorough, well-organized responses.</p>
                            </div>
                        </div>

                        <div className="flex gap-3">
                            <div className="flex-shrink-0 w-16 h-6 rounded-full bg-rose-100 text-rose-600 font-medium flex items-center justify-center">
                                Don't ✕
                            </div>
                            <div>
                                <h3 className="font-medium text-gray-900 mb-2">Do not ask for specific papers</h3>
                                <p className="text-gray-600">SparkAI is trained to answer general questions but cannot search for specific papers or documents. For literature searches, try using Focus, our dedicated research tool.</p>
                            </div>
                        </div>
                        
                        <div className="flex gap-3">
                            <div className="flex-shrink-0 w-16 h-6 rounded-full bg-rose-100 text-rose-600 font-medium flex items-center justify-center">
                                Don't ✕
                            </div>
                            <div>
                                <h3 className="font-medium text-gray-900 mb-2">Always ask questions that can be found in paper literature</h3>
                                <p className="text-gray-600">SparkAI works best when answering questions that can be supported by academic research and published literature. For everyday tasks or general advice, you may receive less precise or helpful responses. Focus on topics that have scholarly backing for optimal results.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-6 border-t border-gray-100">
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={onClose}
                        className="w-full p-2 rounded-xl bg-gradient-to-r from-emerald-500 to-green-500 text-white font-medium hover:shadow-md hover:shadow-emerald-500/20 transition-all"
                    >
                        Got it
                    </motion.button>
                </div>
            </motion.div>
        </motion.div>
    );
} 