import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { motion, AnimatePresence } from 'framer-motion';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { LuZoomIn, LuZoomOut } from "react-icons/lu";
import { HiMiniArrowsPointingOut } from "react-icons/hi2";
import { CircularProgress } from '@mui/material';
import { UserContext } from '../pages/Focus';

// Set up worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

class PDFErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="flex justify-center items-center h-screen">
                    <div className="p-6 bg-white rounded-xl shadow-lg text-center">
                        <p className="text-gray-600 mb-2">Unable to load PDF</p>
                        <p className="text-sm text-gray-400">Please try refreshing the page</p>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

const PDFViewer = ({ url }) => {
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
    const [scale, setScale] = useState(1.2);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [loading, setLoading] = useState(true);
    const containerRef = React.useRef(null);
    const { isChatbotOpen } = React.useContext(UserContext);

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            containerRef.current.requestFullscreen();
            setIsFullscreen(true);
        } else {
            document.exitFullscreen();
            setIsFullscreen(false);
        }
    };

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const pdfProxyUrl = `${process.env.REACT_APP_API_URL}/api/focus/pdf?url=${encodeURIComponent(url)}`;
                const response = await fetch(pdfProxyUrl, {
                    headers: {
                        'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac',
                    }
                });

                if (!response.ok) throw new Error('PDF fetch failed');
                
                const blob = await response.blob();
                setPdfBlobUrl(URL.createObjectURL(blob));
            } catch (error) {
                console.error('Error loading PDF:', error);
            }
        };

        fetchPdf();
    }, [url]);

    const handlePageChange = (delta) => {
        const newPage = currentPage + delta;
        if (newPage >= 1 && newPage <= numPages) {
            setCurrentPage(newPage);
        }
    };

    const handleZoom = (delta) => {
        const newScale = scale + delta;
        if (newScale >= 0.5 && newScale <= 2.5) {
            setScale(newScale);
        }
    };

    return (
        <div 
            ref={containerRef}
            className="flex flex-col items-center min-h-screen bg-gray-50 pt-4 pb-20 overflow-y-auto"
            style={{
                marginRight: isChatbotOpen ? '500px' : '0px',
                width: isChatbotOpen ? 'calc(100% - 500px)' : '100%',
                transition: 'all 0.3s ease-in-out',
                height: '100vh'
            }}
        >
            {/* Controls Bar */}
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50"
            >
                <div className="flex items-center gap-4 p-3 bg-white rounded-xl shadow-lg border border-gray-100">
                    {/* Page Navigation */}
                    <div className="flex items-center gap-2">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handlePageChange(-1)}
                            disabled={currentPage === 1}
                            className={`p-1.5 rounded-lg ${currentPage === 1 ? 'text-gray-300' : 'text-gray-600 hover:bg-gray-100'}`}
                        >
                            <IoChevronBack className="w-5 h-5" />
                        </motion.button>
                        
                        <span className="text-sm font-medium text-gray-600">
                            {currentPage} / {numPages || '?'}
                        </span>

                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === numPages}
                            className={`p-1.5 rounded-lg ${currentPage === numPages ? 'text-gray-300' : 'text-gray-600 hover:bg-gray-100'}`}
                        >
                            <IoChevronForward className="w-5 h-5" />
                        </motion.button>
                    </div>

                    <div className="w-px h-6 bg-gray-200" />

                    {/* Zoom Controls */}
                    <div className="flex items-center gap-2">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleZoom(-0.2)}
                            disabled={scale <= 0.5}
                            className={`p-1.5 rounded-lg ${scale <= 0.5 ? 'text-gray-300' : 'text-gray-600 hover:bg-gray-100'}`}
                        >
                            <LuZoomOut className="w-5 h-5" />
                        </motion.button>

                        <span className="text-sm font-medium text-gray-600">
                            {Math.round(scale * 100)}%
                        </span>

                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleZoom(0.2)}
                            disabled={scale >= 2.5}
                            className={`p-1.5 rounded-lg ${scale >= 2.5 ? 'text-gray-300' : 'text-gray-600 hover:bg-gray-100'}`}
                        >
                            <LuZoomIn className="w-5 h-5" />
                        </motion.button>
                    </div>

                    <div className="w-px h-6 bg-gray-200" />

                    {/* Fullscreen Toggle */}
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={toggleFullscreen}
                        className="p-1.5 rounded-lg text-gray-600 hover:bg-gray-100"
                    >
                        <HiMiniArrowsPointingOut className="w-5 h-5" />
                    </motion.button>
                </div>
            </motion.div>

            {/* PDF Document */}
            <PDFErrorBoundary>
                <Document
                    file={pdfBlobUrl}
                    onLoadSuccess={({ numPages }) => {
                        setNumPages(numPages);
                        setLoading(false);
                    }}
                    loading={
                        <div className="flex flex-col items-center justify-center gap-4 h-[80vh]">
                            <CircularProgress size={40} thickness={4} className="text-purple-500" />
                            <p className="text-gray-500">Loading PDF...</p>
                        </div>
                    }
                    className="w-full flex flex-col items-center"
                >
                    <AnimatePresence mode="wait">
                        {!loading && (
                            <motion.div
                                key={currentPage}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.2 }}
                                className="relative"
                            >
                                <Page
                                    pageNumber={currentPage}
                                    scale={scale}
                                    className="shadow-lg rounded-lg overflow-hidden"
                                    loading={
                                        <div className="w-[800px] h-[1000px] bg-gray-50 rounded-lg flex items-center justify-center">
                                            <CircularProgress size={30} thickness={4} className="text-purple-500" />
                                        </div>
                                    }
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Document>
            </PDFErrorBoundary>
        </div>
    );
};

export default PDFViewer;
