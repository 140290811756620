import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SparkAILogo from '../assets/sparkai_new_only_logo.png';
import { MainUserContext } from '../App';
import axios from 'axios';
import { FaRegFileAlt, FaRegLightbulb, FaSearch } from "react-icons/fa";
import { HiSparkles } from "react-icons/hi2";
import { IoExtensionPuzzle } from "react-icons/io5";
import HomeSidebar from "../components/HomeSidebar";
import { checkAuthStatus, fetchUserData } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import ComingSoonModal from '../components/ComingSoonModal';
import { AnimatePresence } from 'framer-motion';

export const UserContext = React.createContext(null);

export default function Home() {
    const { userId } = React.useContext(MainUserContext);
    const { setUser } = React.useContext(MainUserContext);
    const { setUserId } = React.useContext(MainUserContext);
    const { setLoggedIn } = React.useContext(MainUserContext);
    const { setEmail } = React.useContext(MainUserContext);
    const [recentPapers, setRecentPapers] = React.useState([]);
    const [recentChats, setRecentChats] = React.useState([]);
    const [recentFocusChats, setRecentFocusChats] = React.useState([]);
    const [sparkaiSidebarOpen, setSparkaiSidebarOpen] = React.useState(true);

    const [comingSoonModal, setComingSoonModal] = React.useState(false);
    const [comingSoonFeature, setComingSoonFeature] = React.useState("");

    const handleComingSoonFeature = (featureName) => {
      setComingSoonFeature(featureName);
      setComingSoonModal(true);
    };

    // Auth validation
    React.useEffect(() => {
        const validateAuth = async () => {
            try {
                const user = await checkAuthStatus();
                
                if (user) {
                    const userData = await fetchUserData(user.mail);
                    setUser(userData);
                    setUserId(userData.user_id);
                    setLoggedIn(true);
                    setEmail(userData.mail);
                } else {
                    handleUnauthorized();
                }
            } catch (error) {
                handleUnauthorized();
            }
        };

        const handleUnauthorized = () => {
            setLoggedIn(false);
            window.location.replace('/signin');
        };

        validateAuth();
    }, [setUser, setUserId, setLoggedIn, setEmail]);

    // Handle sidebar on mobile
    React.useEffect(() => {
        if (window.innerWidth < 768) {
            setSparkaiSidebarOpen(false);
        }
    }, []);

    // Fetch recent data
    React.useEffect(() => {
        if (userId) {
            // Fetch recent papers
            axios.get(`${process.env.REACT_APP_API_URL}/api/papers/recent/${userId}`)
                .then(response => setRecentPapers(response.data))
                .catch(error => console.error('Error fetching papers:', error));

            // Fetch recent chats
            axios.get(`${process.env.REACT_APP_API_URL}/api/chats/recent/${userId}`)
                .then(response => setRecentChats(response.data))
                .catch(error => console.error('Error fetching chats:', error));

            // Fetch recent focus chats
            axios.get(`${process.env.REACT_APP_API_URL}/api/focus/recent/${userId}`)
                .then(response => setRecentFocusChats(response.data))
                .catch(error => console.error('Error fetching focus chats:', error));
        }
    }, [userId]);

    React.useEffect(() => {
        console.log(recentFocusChats);
    }, [recentChats]);

    return (
        <UserContext.Provider value={{ sparkaiSidebarOpen, setSparkaiSidebarOpen }}>
              <AnimatePresence>
                {/* ... existing code ... */}
                
                {/* Add the ComingSoonModal component */}
                <ComingSoonModal 
                    isOpen={comingSoonModal}
                    onClose={() => setComingSoonModal(false)}
                    featureName={comingSoonFeature}
                />
            </AnimatePresence>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <HomeSidebar 
                    setSidebarOpen={setSparkaiSidebarOpen} 
                    sidebarOpen={sparkaiSidebarOpen} 
                />
                
                {/* Main Content */}
                <div 
                    className={`flex-1 transition-all duration-300 ease-in-out bg-gradient-to-b from-gray-50 to-white overflow-y-auto`}
                    style={{
                        marginLeft: sparkaiSidebarOpen ? '70px' : '70px',
                        paddingLeft: sparkaiSidebarOpen ? '30px' : '0px',
                    }}
                >
                    <div className="max-w-6xl mx-auto">
                        {/* Header */}
                        <div className="pt-12 pb-16 text-center">
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="flex flex-col items-center gap-3"
                            >
                                <motion.div
                                    initial={{ scale: 0.8 }}
                                    animate={{ scale: 1 }}
                                    transition={{ 
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20 
                                    }}
                                >
                                    <img 
                                        src={SparkAILogo} 
                                        alt="SparkAI" 
                                        className="w-10 h-10" 
                                    />
                                </motion.div>
                                <div className="space-y-2">
                                    <h1 className="text-4xl font-bold bg-gradient-to-r from-emerald-600 via-teal-500 to-green-600 bg-clip-text text-transparent">
                                        Welcome to SparkAI
                                    </h1>
                                    <p className="text-gray-600 text-lg">
                                        Your intelligent research companion
                                    </p>
                                </div>
                            </motion.div>
                        </div>

                        {/* Main Content */}
                        <div className="px-4 sm:px-6 lg:px-8 pb-12">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {/* Quick Actions */}
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.1 }}
                                    className="col-span-full"
                                >
                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                        <QuickActionCard
                                            to="/chat"
                                            icon={<HiSparkles className="w-6 h-6 text-emerald-500" />}
                                            title="New Chat"
                                            description="Start a new conversation with SparkAI"
                                            gradient="from-emerald-50 to-green-50"
                                            hoverGradient="from-emerald-100 to-green-100"
                                        />
                                        <QuickActionCard
                                            to="/focus"
                                            icon={<IoExtensionPuzzle className="w-6 h-6 text-blue-500" />}
                                            title="Focus Mode"
                                            description="Deep dive into research papers"
                                            gradient="from-blue-50 to-indigo-50"
                                            hoverGradient="from-blue-100 to-indigo-100"
                                        />
                                        <QuickActionCard
                                            icon={<FaSearch className="w-6 h-6 text-purple-500" />}
                                            title="Search Papers"
                                            description="Find research papers in our database"
                                            gradient="from-purple-50 to-pink-50"
                                            hoverGradient="from-purple-100 to-pink-100"
                                            onClick={() => handleComingSoonFeature("Search Papers")}
                                        />
                                        <QuickActionCard
                                            icon={<FaRegFileAlt className="w-6 h-6 text-amber-500" />}
                                            title="Manage Papers"
                                            description="Manage your saved papers"
                                            gradient="from-amber-50 to-orange-50"
                                            hoverGradient="from-amber-100 to-orange-100"
                                            onClick={() => handleComingSoonFeature("Manage Papers")}
                                        />
                                    </div>
                                </motion.div>

                                {/* Recent Chats */}
                                {recentChats.length > 0 && (
                                    <RecentChatsSection
                                        title="Recent Chats"
                                        chats={recentChats}
                                        icon={<HiSparkles className="w-5 h-5 text-emerald-500" />}
                                        delay={0.3}
                                    />
                                )}

                                {/* Recent Focus Chats */}
                                {recentFocusChats.length > 0 && (
                                    <RecentFocusChatsSection
                                        title="Recent Focus Sessions"
                                        focusChats={recentFocusChats}
                                        icon={<IoExtensionPuzzle className="w-5 h-5 text-blue-500" />}
                                        delay={0.4}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
}

function QuickActionCard({ to, icon, title, description, gradient, hoverGradient, onClick }) {
    return (
        <Link to={to} onClick={onClick}>
            <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={`p-4 rounded-xl bg-gradient-to-r ${gradient} hover:bg-gradient-to-r hover:${hoverGradient} transition-all duration-200 h-full`}
            >
                <div className="flex flex-col gap-2">
                    {icon}
                    <h3 className="font-semibold text-gray-800">{title}</h3>
                    <p className="text-sm text-gray-600 hidden md:block">{description}</p>
                </div>
            </motion.div>
        </Link>
    );
}

function RecentChatsSection({ title, chats, icon, delay }) {

    React.useEffect(() => {
        console.log(chats);
    }, [chats]);

    const [ url, setUrl ] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (title === "Recent Focus Sessions") {
            setUrl(`/focus/?chat_id=`);
        } else {
            setUrl(`/chat/?chat_id=`);
        }
    }, [title, chats]);

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay }}
            className="bg-white rounded-xl p-6 shadow-sm border border-gray-100"
        >
            <div className="flex items-center gap-2 mb-4">
                {icon}
                <h2 className="text-lg font-semibold text-gray-800">{title ? title : "Recent Chats"}</h2>
            </div>
            <div className="space-y-3">
                {chats.map((chat) => (
                    <motion.div
                        key={chat.conversation_id}
                        whileHover={{ x: 4 }}
                        className="p-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
                        onClick={() => {
                            navigate(`${url}${chat.conversation_id}`)
                        }}
                    >
                        <div className="flex justify-between items-center">
                            <h3 className="text-sm font-medium text-gray-700 truncate">
                                {chat.conversation_name}
                            </h3>
                            <span className="text-xs text-gray-400">
                                {new Date(chat.updated_at).toLocaleDateString()}
                            </span>
                        </div>
                    </motion.div>
                ))}
            </div>
        </motion.div>
    );
}

function RecentFocusChatsSection({ focusChats, icon, delay }) {
    
  return (
    <RecentChatsSection title="Recent Focus Sessions" chats={focusChats} icon={icon} delay={delay} />
  )
}

function RecentPapersSection({ papers, icon, delay }) {
  return (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay }}
        className="bg-white rounded-xl p-6 shadow-sm border border-gray-100"
    >
        <div className="flex items-center gap-2 mb-4">
            {icon}
            <h2 className="text-lg font-semibold text-gray-800">Recent Papers</h2>
        </div>
        <div className="space-y-3">
            {papers.map((paper) => (
                <motion.div
                    key={paper.id}
                    whileHover={{ x: 4 }}
                    className="p-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
                >
                    <div className="flex justify-between items-center">
                        <h3 className="text-sm font-medium text-gray-700 truncate">
                            {paper.title}
                        </h3>
                        <span className="text-xs text-gray-400">
                            {new Date(paper.date).toLocaleDateString()}
                        </span>
                    </div>
                </motion.div>
            ))}
        </div>
    </motion.div>
  );
}
