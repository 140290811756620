import * as React from 'react';

import { FiMessageSquare } from "react-icons/fi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { HiMiniChatBubbleBottomCenter } from "react-icons/hi2";


import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ListDivider from '@mui/joy/ListDivider';
import Input from '@mui/joy/Input';
import { Box, Stack } from '@mui/joy';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import { CgRename } from "react-icons/cg";
import { MdArticle, MdDeleteOutline } from "react-icons/md";

import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

import axios from 'axios';

import '../index.css';

import { UserContext } from '../pages/Focus';
import { MainUserContext } from '../App';
import { Tooltip } from '@mui/material';

import { motion } from "framer-motion";
import { AnimatePresence } from 'framer-motion';


function RenameChatModal({isOpen, setIsOpen, chatId}) {

    const [newChatName, setNewChatName] = React.useState('');
    
    const renameChat = ( newName ) => {
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/rename', {
            conversation_id: chatId,
            conversation_name: newName
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <React.Fragment>
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Sheet
            variant="outlined"
            sx={{ width: 800, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
            >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                sx={{ fontWeight: 'lg', mb: 1, textAlign: 'center' }}
            >
                Rename chat
            </Typography>
            <form
                onSubmit={(e) => {
                e.preventDefault();
                renameChat(newChatName);
                setNewChatName('');
                setIsOpen(false);
            }}>
                <Input required
                    placeholder="New chat name"
                    value={newChatName}
                    onChange={(e) => setNewChatName(e.target.value)}
                    sx={{ width: '100%', mb: 2 }}
                />
            </form>
            </Sheet>
        </Modal>
        </React.Fragment>
    );
}

function ChatButtonMenu( {setIsOpen, chatId} ) {

    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { setMessages } = React.useContext(UserContext);
    const { setCurrentChatId} = React.useContext(UserContext);
    const deleteChat = () => {
        setMessages([]);
        setCurrentChatId(null);
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/delete', {
            conversation_id: chatId
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <Dropdown>
            <MenuButton variant="plain">
                <EllipsisHorizontalIcon
                    className='w-5 h-5 cursor-pointer hover:bg-white rounded-md'
                />
            </MenuButton>
            <Menu sx={{zIndex: '9999'}}>
                <MenuItem onClick={() => setIsOpen(true)} disabled={messageIsLoading}>
                    <ListItemDecorator>
                        <CgRename/>
                    </ListItemDecorator>
                    <text
                        className='text-gray-500 text-sm font-semibold'
                    >
                        Rename
                    </text>
                </MenuItem>
                <ListDivider/>
                <MenuItem variant='soft' color='danger' disabled={messageIsLoading}
                    onClick={() => deleteChat()}>
                    <ListItemDecorator>
                        <MdDeleteOutline/>
                    </ListItemDecorator>
                    <text
                        className='text-red-500 text-sm font-semibold'
                    >
                        Delete
                    </text>
                </MenuItem>
            </Menu>
        </Dropdown>
    )
}

function SnackbarWithDecorators({alertOpen, setAlertOpen}) {
  
    return (
      <React.Fragment>
        <Snackbar
          variant="soft"
          autoHideDuration={2000}
          color="success"
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
          endDecorator={
            <Button
              onClick={() => setAlertOpen(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
        >
          Chat selected successfully!
        </Snackbar>
      </React.Fragment>
    );
  }

function ChatButton({chat, chatName, chatId, setCurrentChatName}) {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [editedName, setEditedName] = React.useState(chatName);
    const inputRef = React.useRef(null);
    const { messageIsLoading, currentChatId, setCurrentChatId, setSparkaiSidebarOpen, setMessages, setChats } = React.useContext(UserContext);

    // Update editedName when chatName changes
    React.useEffect(() => {
        setEditedName(chatName);
    }, [chatName]);

    const handleRename = () => {
        if (editedName.trim() === '') return;
        if (editedName === chatName) {
            setIsEditing(false);
            return;
        }

        axios.post(process.env.REACT_APP_API_URL + '/api/chats/rename', {
            conversation_id: chatId,
            conversation_name: editedName
        }).then(response => {
            setIsEditing(false);
            if (currentChatId == chatId) {
                setCurrentChatName(editedName);
            }
            setChats(prevChats => 
                prevChats.map(chat => 
                    chat.conversation_id == chatId 
                        ? {...chat, conversation_name: editedName}
                        : chat
                )
            );
        }).catch(error => {
            console.error('Error renaming chat:', error);
            setEditedName(chatName);
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleRename();
            e.preventDefault();
        } else if (e.key === 'Escape') {
            setEditedName(chatName);
            setIsEditing(false);
            e.preventDefault();
        }
    };

    React.useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);

    const handleChatSelect = () => {
        if (chatId == currentChatId) return;
        setCurrentChatName(chatName);
        setCurrentChatId(chatId);
        window.innerWidth <= 768 && setSparkaiSidebarOpen(false);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const deleteChat = () => {
        setMessages([]);
        setCurrentChatId(null);
        setCurrentChatName('');
        setChats(prevChats => prevChats.filter(chat => chat.conversation_id !== chatId));
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/delete', {
            conversation_id: chatId
        }).then(response => {
        }).catch(error => {
            console.error('Error deleting chat:', error);
        });
    };

    return (
        <motion.div
            initial={false}
            animate={{ 
                backgroundColor: currentChatId === chatId ? 'rgb(243 244 246)' : 'transparent',
                scale: isHovered ? 1.02 : 1
            }}
            whileTap={{ scale: 0.98 }}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            onClick={!isEditing ? handleChatSelect : undefined}
            className="relative w-full group cursor-pointer"
        >
            <div className={`p-2.5 rounded-xl transition-all duration-200 ${
                currentChatId == chatId 
                    ? 'bg-gradient-to-r from-purple-50 to-cyan-50' 
                    : 'hover:bg-gray-50'
            }`}>
                <div className="flex items-center gap-3">
                    <div className="relative flex-shrink-0">
                        <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${
                            currentChatId == chatId 
                                ? 'bg-purple-300' 
                                : 'bg-cyan-100'
                        }`}>
                            <MdArticle className={`w-4 h-4 ${
                                currentChatId == chatId 
                                    ? 'text-purple-50' 
                                    : 'text-gray-600'
                            }`} />
                        </div>
                    </div>
                    
                    <div className="flex-1 min-w-0">
                        {isEditing ? (
                            <input
                                ref={inputRef}
                                type="text"
                                value={editedName}
                                onChange={(e) => setEditedName(e.target.value)}
                                onBlur={handleRename}
                                onKeyDown={handleKeyDown}
                                className="w-full text-sm font-medium bg-transparent border-none focus:ring-0 focus:outline-none text-gray-700"
                                onClick={(e) => e.stopPropagation()}
                            />
                        ) : (
                            <p className={`text-sm font-medium truncate ${
                                currentChatId == chatId 
                                    ? 'text-purple-700' 
                                    : 'text-gray-700'
                            }`}>
                                {chatName}
                            </p>
                        )}
                        <p className="text-xs text-gray-400 truncate">
                            {formatDate(chat.updated_at)}
                        </p>
                    </div>

                    <AnimatePresence>
                        {isHovered && !isEditing && (
                            <motion.div
                                initial={{ opacity: 0, x: 10 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 10 }}
                                className="flex items-center gap-1"
                            >
                                <Tooltip title="Rename">
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsEditing(true);
                                        }}
                                        className="p-1.5 rounded-full hover:bg-purple-50"
                                    >
                                        <CgRename className="w-4 h-4 text-gray-400 hover:text-purple-600" />
                                    </motion.button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            deleteChat();
                                        }}
                                        className="p-1.5 rounded-full hover:bg-cyan-50"
                                    >
                                        <MdDeleteOutline className="w-4 h-4 text-gray-400 hover:text-cyan-500" />
                                    </motion.button>
                                </Tooltip>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </motion.div>
    );
}

export default function ChatList({filterName, setCurrentChatName, hidden}) {
    const { chats, setChats } = React.useContext(UserContext);
    const { isOpen } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/focus/chat/' + userId)
        .then(response => {
            setChats(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    }, [userId]);
    
    const groupChatsByDate = (chats) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        return chats.reduce((groups, chat) => {
            const date = new Date(chat.updated_at);
            date.setHours(0, 0, 0, 0);
            const diffTime = today.getTime() - date.getTime();
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            
            let group;
            if (diffDays === 0) group = 'Today';
            else if (diffDays === 1) group = 'Yesterday';
            else if (diffDays < 7) group = `${diffDays} days ago`;
            else if (diffDays < 30) group = 'Last week';
            else if (diffDays < 60) group = 'A month ago';
            else group = date.toLocaleDateString('en-GB', { 
                month: 'long', 
                year: 'numeric' 
            });

            if (!groups[group]) groups[group] = [];
            groups[group].push(chat);
            return groups;
        }, {});
    };

    const filteredAndGroupedChats = React.useMemo(() => {
        const filtered = chats.filter(chat => 
            chat.conversation_name.toLowerCase().includes(filterName.toLowerCase())
        );
        return groupChatsByDate(filtered);
    }, [chats, filterName]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            key={isOpen}
            className={`space-y-6 ${hidden ? 'hidden' : ''}`}
        >
            {Object.entries(filteredAndGroupedChats).map(([dateGroup, groupChats]) => (
                <motion.div
                    key={dateGroup}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="space-y-2"
                >
                    <div className="sticky top-[-10px] px-2 py-1.5 bg-white/80 backdrop-blur-sm z-10">
                        <div className="flex items-center gap-2">
                            <div className="h-px flex-1 bg-gray-100" />
                            <span className="text-xs font-medium text-gray-400">
                                {dateGroup}
                            </span>
                            <div className="h-px flex-1 bg-gray-100" />
                        </div>
                    </div>
                    <div className="space-y-1">
                        {groupChats.map(chat => (
                            <ChatButton
                                key={chat.conversation_id}
                                chat={chat}
                                chatName={chat.conversation_name}
                                chatId={chat.conversation_id}
                                setCurrentChatName={setCurrentChatName}
                            />
                        ))}
                    </div>
                </motion.div>
            ))}
        </motion.div>
    );
}