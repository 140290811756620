import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { UserContext } from '../pages/Focus';
import { IoSend } from "react-icons/io5";
import { motion, AnimatePresence } from 'framer-motion';
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { CircularProgress } from "@mui/material";
import { LuMessagesSquare } from "react-icons/lu";

export default function FloatingChatBot() {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const { paper } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const {isChatbotOpen, setIsChatbotOpen} = React.useContext(UserContext);
    const [messageIsLoading, setMessageIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [streamingMessage, setStreamingMessage] = useState('');

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then(response => {
                setMessages(response.data);
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId]);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, messageIsLoading]);

    const handleSendMessage = async () => {
        if (newMessage.trim() === "") return;

        setMessageIsLoading(true);
        const userMessage = { role_id: 1, content: newMessage };
        setMessages(prev => [...prev, userMessage]);
        setNewMessage("");
        setStreamingMessage('');

        console.log('[FloatingChatBot] Sending message');
        try {
            const qdrantResponse = await axios.post(process.env.REACT_APP_API_URL + '/api/qdrant/search/', {
                paper_id: paper.paper_id,
                query: newMessage
            });

            await axios.post(process.env.REACT_APP_API_URL + '/api/messages/', {
                conversation_id: currentChatId,
                role_id: 1,
                message: newMessage
            });

            console.log('[FloatingChatBot] Calling API');
            // Make streaming request with fetch
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/ia/focus/call/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `c46801db-29ee-408c-bf24-6b16ba8131ac`,
                },
                body: JSON.stringify({
                    paper_title: paper.title,
                    paper_abstract: paper.abstract,
                    paper_authors: paper.authors,
                    chat_history: [...messages, userMessage],
                    context: qdrantResponse.data.extracts,
                    user_query: newMessage
                })
            });

            console.log('[FloatingChatBot] API response');

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('[FloatingChatBot] Starting stream processing');
            
            // Create a new ReadableStream from the response body
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedMessage = '';

            try {
                while (true) {
                    const { value, done } = await reader.read();
                    if (done) {
                        console.log('[FloatingChatBot] Stream complete');
                        break;
                    }

                    const chunk = decoder.decode(value);
                    console.log('[FloatingChatBot] Received chunk:', chunk);

                    const lines = chunk.split('\n');
                    for (const line of lines) {
                        if (line.trim() === '') continue;
                        if (line.startsWith('data: ')) {
                            try {
                                const data = JSON.parse(line.slice(6));
                                console.log('[FloatingChatBot] Parsed data:', data);
                                
                                if (data.done) {
                                    await axios.post(process.env.REACT_APP_API_URL + '/api/messages/', {
                                        conversation_id: currentChatId,
                                        role_id: 2,
                                        message: accumulatedMessage
                                    });
                                    
                                    setMessages(prev => [...prev, { role_id: 2, content: accumulatedMessage }]);
                                    setStreamingMessage('');
                                    break;
                                } else if (data.error) {
                                    console.error('[FloatingChatBot] Stream error:', data.error);
                                    break;
                                } else if (data.content) {
                                    accumulatedMessage += data.content;
                                    setStreamingMessage(accumulatedMessage);
                                }
                            } catch (e) {
                                console.error('[FloatingChatBot] Error parsing SSE data:', e, line);
                            }
                        }
                    }
                }
            } catch (streamError) {
                console.error('[FloatingChatBot] Stream processing error:', streamError);
            }

        } catch (error) {
            console.error('[FloatingChatBot] Error in chat sequence:', error);
        } finally {
            setMessageIsLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <div className="flex">
            <motion.div 
                className="fixed right-0 top-[64px] flex h-[calc(100vh-64px)]"
                initial={false}
                animate={{ 
                    width: isChatbotOpen 
                        ? windowWidth <= 768 
                            ? '100%' 
                            : '500px' 
                        : '40px' 
                }}
                transition={{ type: "spring", stiffness: 200, damping: 25 }}
            >
                <AnimatePresence>
                    {!isChatbotOpen && (
                        <motion.button
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -20 }}
                            onClick={() => setIsChatbotOpen(true)}
                            className="fixed right-32 top-[80px] flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-purple-500 to-cyan-500 text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300"
                        >
                            <LuMessagesSquare className="w-5 h-5" />
                            <span className="font-medium">Open Chat</span>
                        </motion.button>
                    )}
                </AnimatePresence>

                <motion.div
                    className="w-full h-full bg-white border-l border-t-4 border-purple-600 flex flex-col absolute right-0 shadow-xl"
                    animate={{ 
                        x: isChatbotOpen ? 0 : '100%'
                    }}
                    transition={{ type: "spring", stiffness: 200, damping: 25 }}
                >
                    {/* Chat Header */}
                    <div className="p-4 border-b bg-white flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => setIsChatbotOpen(false)}
                                className="p-1 rounded-lg hover:bg-gray-100"
                            >
                                <HiChevronRight className="w-6 h-6 text-gray-600" />
                            </motion.button>
                            <div className="flex items-center gap-2">
                                <div className="w-2 h-2 rounded-full bg-purple-500" />
                                <span className="font-medium text-gray-700">Focus Chat</span>
                            </div>
                        </div>
                    </div>

                    {/* Chat Messages */}
                    <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50">
                        {messages.map((message, index) => (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                key={index}
                                className={`flex ${message.role_id === 1 ? 'justify-end' : 'justify-start'}`}
                            >
                                <div className={`max-w-[80%] rounded-2xl p-4 ${
                                    message.role_id === 1 
                                        ? 'bg-gradient-to-r from-purple-500 to-cyan-500 text-white' 
                                        : 'bg-white border border-gray-200'
                                }`}>
                                    <ReactMarkdown className="prose prose-sm max-w-none">
                                        {message.content}
                                    </ReactMarkdown>
                                </div>
                            </motion.div>
                        ))}
                        {streamingMessage && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                className="flex justify-start"
                            >
                                <div className="bg-white border border-gray-200 rounded-2xl p-4">
                                    <ReactMarkdown className="prose prose-sm max-w-none">
                                        {streamingMessage}
                                    </ReactMarkdown>
                                </div>
                            </motion.div>
                        )}
                        {messageIsLoading && !streamingMessage && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                className="flex justify-start"
                            >
                                <div className="bg-white border border-gray-200 rounded-2xl p-4">
                                    <CircularProgress size={20} thickness={5} className="text-purple-500" />
                                </div>
                            </motion.div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>

                    {/* Chat Input */}
                    <div className="p-4 bg-white border-t">
                        <div className="relative">
                            <textarea
                                ref={textareaRef}
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Type your message..."
                                disabled={messageIsLoading}
                                className="w-full pl-4 pr-12 py-3 bg-gray-50 rounded-xl border border-gray-200 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 outline-none resize-none"
                                style={{ maxHeight: '120px', minHeight: '56px' }}
                            />
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={handleSendMessage}
                                disabled={messageIsLoading || !newMessage.trim()}
                                className={`absolute right-2 bottom-2 p-2 rounded-lg transition-all duration-300 ${
                                    messageIsLoading || !newMessage.trim()
                                        ? 'bg-gray-100 text-gray-400'
                                        : 'bg-gradient-to-r from-purple-500 to-cyan-500 text-white shadow-sm hover:shadow-md'
                                }`}
                            >
                                <IoSend className="w-4 h-4" />
                            </motion.button>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
}