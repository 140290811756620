import React from "react";
import { Container, Grid, Box, Avatar, Typography, Button, Card, CardContent, Menu, MenuItem, Stack } from "@mui/joy";
import { AccountCircle, CalendarToday, Language, Email, Public, Money, Lock } from "@mui/icons-material";
import { MainUserContext } from "../App";
import axios from "axios";
import { UserCircleIcon, EnvelopeIcon, BuildingOfficeIcon, ArrowLeftEndOnRectangleIcon, ArrowUturnLeftIcon, PencilSquareIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { motion, AnimatePresence } from "framer-motion";
import { FaBolt, FaKey, FaGlobe } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { checkAuthStatus, fetchUserData } from '../services/authService';

const PersonalInfo = ({ user }) => {
    const { setUser } = React.useContext(MainUserContext);
    const [editName, setEditName] = React.useState(false);
    const [userName, setUserName] = React.useState(user.name);

    const handleChangeUserName = (e) => {
        e.preventDefault();
        setUser({ ...user, name: userName });
        setEditName(false);
        axios.put(process.env.REACT_APP_API_URL + '/api/users/change_name/', { id: user.user_id, name: userName })
            .then((response) => {
                console.log('Name changed:', response.data);
            })
            .catch((error) => {
                console.error('Error changing name:', error);
            });
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-6"
        >
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
                <p className="text-gray-600 text-sm">
                    Manage your personal information, including your name, email and institution.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Name Card */}
                <motion.div 
                    className="relative group overflow-hidden"
                    whileHover={{ scale: 1.01 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex justify-between items-start mb-4">
                            <div className="flex items-center gap-2">
                                <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                    <UserCircleIcon className="w-5 h-5 text-emerald-600" />
                                </div>
                                <h3 className="font-medium text-gray-800">Name</h3>
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => setEditName(!editName)}
                                className="text-emerald-600 hover:text-emerald-700"
                            >
                                {!editName ? (
                                    <PencilSquareIcon className="w-5 h-5" />
                                ) : (
                                    <XCircleIcon className="w-5 h-5 text-red-500" />
                                )}
                            </motion.button>
                        </div>

                        {!editName ? (
                            <p className="text-gray-600">
                                {user.name || "Not specified"}
                            </p>
                        ) : (
                            <form onSubmit={handleChangeUserName} className="space-y-3">
                                <input
                                    className="w-full px-3 py-2 rounded-lg border border-emerald-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none transition-all duration-200"
                                    type="text"
                                    placeholder="Enter your name"
                                    defaultValue={user.name}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    type="submit"
                                    className="w-full py-2 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
                                >
                                    Save Changes
                                </motion.button>
                            </form>
                        )}
                    </div>
                </motion.div>

                {/* Email Card */}
                <motion.div 
                    className="relative group overflow-hidden"
                    whileHover={{ scale: 1.01 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <EnvelopeIcon className="w-5 h-5 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Email</h3>
                        </div>
                        <p className="text-gray-600">{user.mail}</p>
                    </div>
                </motion.div>

                {/* Institution Card */}
                <motion.div 
                    className="relative group overflow-hidden md:col-span-2"
                    whileHover={{ scale: 1.01 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <BuildingOfficeIcon className="w-5 h-5 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Institution / Company</h3>
                        </div>
                        <p className="text-gray-600">
                            {user.institution || "Not affiliated"}
                        </p>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
}

const PlansBilling = ({ user }) => {
    const [expiryDate, setExpiryDate] = React.useState(user.expiry_date);
    const [plan, setPlan] = React.useState("Free");

    React.useEffect(() => {
        if (user.expiry_date) {
            const date = new Date(user.expiry_date);
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            setExpiryDate(formattedDate);
        } else {
            setExpiryDate('Forever');
        }

        if (user.subscribed && !user.expiry_date) {
            setPlan("Beta tester");
        } else {
            setPlan("Free (limited)");
        }
    }, [user.expiry_date, user.subscribed]);

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="space-y-6"
        >
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold text-gray-800">Plans & Billing</h2>
                <p className="text-gray-600 text-sm">
                    Manage your subscription and billing information.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <motion.div 
                    className="relative group overflow-hidden"
                    whileHover={{ scale: 1.01 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <FaBolt className="w-4 h-4 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Current Plan</h3>
                        </div>
                        <p className="text-gray-600">{plan}</p>
                    </div>
                </motion.div>

                <motion.div 
                    className="relative group overflow-hidden"
                    whileHover={{ scale: 1.01 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <CalendarToday className="w-4 h-4 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Expiry Date</h3>
                        </div>
                        <p className="text-gray-600">{expiryDate}</p>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
};

const Security = ({ user }) => {
    const { setUser } = React.useContext(MainUserContext);
    const [editPassword, setEditPassword] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [passwordMessage, setPasswordMessage] = React.useState('');
    const [oldPasswordMessage, setOldPasswordMessage] = React.useState('');

    const handleChangePassword = (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            setPasswordMessage("Passwords don't match");
            return;
        }

        axios.put(process.env.REACT_APP_API_URL + '/api/users/change_password/', {
            id: user.user_id,
            old_password: oldPassword,
            new_password: password
        })
        .then((response) => {
            setPasswordMessage('Password changed successfully');
            setEditPassword(false);
            setOldPassword('');
            setPassword('');
            setConfirmPassword('');
        })
        .catch((error) => {
            if (error.response.status === 401) {
                setOldPasswordMessage('Current password is incorrect');
            } else {
                setPasswordMessage('Error changing password');
            }
        });
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="space-y-6"
        >
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold text-gray-800">Security Settings</h2>
                <p className="text-gray-600 text-sm">
                    Manage your password and security preferences.
                </p>
            </div>

            <div className="relative group overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                    <div className="flex justify-between items-start mb-4">
                        <div className="flex items-center gap-2">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <FaKey className="w-4 h-4 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Password</h3>
                        </div>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => setEditPassword(!editPassword)}
                            className="text-emerald-600 hover:text-emerald-700"
                        >
                            {!editPassword ? (
                                <PencilSquareIcon className="w-5 h-5" />
                            ) : (
                                <XCircleIcon className="w-5 h-5 text-red-500" />
                            )}
                        </motion.button>
                    </div>

                    {!editPassword ? (
                        <p className="text-gray-600">
                            ••••••••
                        </p>
                    ) : (
                        <form onSubmit={handleChangePassword} className="space-y-4">
                            <div>
                                <input
                                    type="password"
                                    placeholder="Current password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border border-emerald-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none transition-all duration-200"
                                />
                                {oldPasswordMessage && (
                                    <p className="mt-1 text-xs text-red-500">{oldPasswordMessage}</p>
                                )}
                            </div>
                            <div>
                                <input
                                    type="password"
                                    placeholder="New password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border border-emerald-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none transition-all duration-200"
                                />
                            </div>
                            <div>
                                <input
                                    type="password"
                                    placeholder="Confirm new password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border border-emerald-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none transition-all duration-200"
                                />
                                {passwordMessage && (
                                    <p className="mt-1 text-xs text-red-500">{passwordMessage}</p>
                                )}
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                type="submit"
                                className="w-full py-2 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
                            >
                                Update Password
                            </motion.button>
                        </form>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

const Account = () => {
    const { user, setUser, userId, setUserId, loggedIn, setLoggedIn, email, setEmail } = React.useContext(MainUserContext);
    const [activeTab, setActiveTab] = React.useState('personal');
    const navigate = useNavigate();

    React.useEffect(() => {
        const validateAuth = async () => {
            try {
                const user = await checkAuthStatus();
                if (user) {
                    const userData = await fetchUserData(user.mail);
                    setUser(userData);
                    setUserId(userData.user_id);
                    setLoggedIn(true);
                    setEmail(userData.mail);
                } else {
                    handleUnauthorized();
                }
            } catch (error) {
                handleUnauthorized();
            }
        };

        validateAuth();
    }, [setUser, setUserId, setLoggedIn, setEmail]);

    const handleUnauthorized = () => {
        setLoggedIn(false);
        window.location.replace('/signin');
    };

    const logOut = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/logout', {withCredentials: true})
            .then(response => {
                window.open('https://ederspark.com/?page=platform', '_self');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
        setLoggedIn(false);
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
            <Helmet>
                <title>EderSpark Account</title>
            </Helmet>

            {/* Header */}
            <div className="bg-white border-b border-gray-100">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <h1 className="text-xl font-semibold text-gray-800">
                            Account Settings
                        </h1>
                        <div className="flex items-center gap-3">
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={() => navigate('/chat')}
                                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200 transition-all duration-200"
                            >
                                <ArrowUturnLeftIcon className="w-4 h-4" />
                                <span>Back to Chat</span>
                            </motion.button>
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={logOut}
                                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-red-50 text-red-600 hover:bg-red-100 transition-all duration-200"
                            >
                                <ArrowLeftEndOnRectangleIcon className="w-4 h-4" />
                                <span>Log out</span>
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex gap-8">
                    {/* Sidebar */}
                    <div className="w-64 flex-shrink-0">
                        <div className="bg-white rounded-xl border border-gray-200 p-4 shadow-sm">
                            {/* User Profile */}
                            <div className="flex flex-col items-center pb-4 border-b border-gray-100">
                                <div className="w-20 h-20 rounded-full bg-gradient-to-r from-emerald-500 to-green-500 flex items-center justify-center text-white text-2xl font-semibold mb-3">
                                    {user.name ? user.name.charAt(0).toUpperCase() : 'U'}
                                </div>
                                <h2 className="text-lg font-medium text-gray-800">
                                    {user.name || "Not specified"}
                                </h2>
                                <p className="text-sm text-gray-500">{user.mail}</p>
                            </div>

                            {/* Navigation */}
                            <nav className="mt-4 space-y-1">
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setActiveTab('personal')}
                                    className={`w-full flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                                        activeTab === 'personal' 
                                            ? 'bg-emerald-50 text-emerald-600' 
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <UserCircleIcon className="w-5 h-5" />
                                    Personal Info
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setActiveTab('billing')}
                                    className={`w-full flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                                        activeTab === 'billing' 
                                            ? 'bg-emerald-50 text-emerald-600' 
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <FaBolt className="w-4 h-4" />
                                    Plans & Billing
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setActiveTab('security')}
                                    className={`w-full flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                                        activeTab === 'security' 
                                            ? 'bg-emerald-50 text-emerald-600' 
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <FaKey className="w-4 h-4" />
                                    Security
                                </motion.button>
                            </nav>
                        </div>
                    </div>

                    {/* Main Content Area */}
                    <div className="flex-1">
                        <div className="bg-white rounded-xl border border-gray-200 p-6 shadow-sm">
                            <AnimatePresence mode="wait">
                                {activeTab === 'personal' && <PersonalInfo user={user} />}
                                {activeTab === 'billing' && <PlansBilling user={user} />}
                                {activeTab === 'security' && <Security user={user} />}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
