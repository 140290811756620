import Input from '@mui/joy/Input';
import React, { useState } from 'react';
import { ReactComponent as Logo} from '../assets/logo-no-slogan-no-background.svg';
import { FaUser } from "react-icons/fa6";
import { IoShieldCheckmark } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import axios from 'axios';
import { useEffect } from 'react';
import { MainUserContext } from '../App';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function ForgotPassword() {
    const { loggedIn } = React.useContext(MainUserContext);
    const [emailError, setEmailError] = useState('');
    const [requestSuccess, setRequestSuccess] = useState('');
    const [requestEmail, setRequestEmail] = useState('');
    const navigate = useNavigate();
  
    useEffect(() => {
      if (loggedIn) {
          navigate('/chat');
      }
    }, [loggedIn, navigate]);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setEmailError('');
      setRequestSuccess('');
      
      axios.post(process.env.REACT_APP_API_URL + '/api/request-password-reset', {
        mail: requestEmail,
      }).then(response => {
        setRequestSuccess('Password reset instructions sent to your email');
        // Clear the email field after success
        setRequestEmail('');
      }).catch(error => {
        console.error(error);
        setEmailError('Unable to process request. Please try again.');
      });
    }

    return (
        <motion.main 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen flex items-center justify-center bg-gradient-to-br from-emerald-50/40 via-white to-green-50/40"
        >
            <Helmet>
                <title>Reset Password - SparkAI</title>
            </Helmet>

            <div className="w-full max-w-[1200px] h-[600px] mx-4 flex rounded-2xl overflow-hidden shadow-2xl bg-white">
                {/* Left Panel - Form Side */}
                <motion.div 
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                    className="w-full lg:w-1/2 p-8 sm:p-12 flex flex-col justify-center relative order-2 lg:order-1"
                >
                    {/* Logo positioned absolutely */}
                    <div className="absolute top-[-50px] left-[50px]">
                        <Logo className="w-[200px] h-[200px]" />
                    </div>

                    <div className="w-full max-w-md mx-auto space-y-8 mt-16">
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">Reset your password</h1>
                            <p className="mt-2 text-gray-600">Enter your email address and we'll send you instructions to reset your password.</p>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <Input
                                    sx={{
                                        '--Input-focusedThickness': '2px',
                                        '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.2)',
                                        '--Input-radius': '0.75rem',
                                        '--Input-gap': '0.75rem',
                                        '--Input-placeholderOpacity': 0.5,
                                        'backgroundColor': 'rgb(249, 250, 251)',
                                        '&:hover': {
                                            '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.28)',
                                            'backgroundColor': 'rgb(243, 244, 246)',
                                        },
                                    }}
                                    className="hover:shadow-sm transition-all duration-200"
                                    startDecorator={<IoMailOutline className="text-emerald-500 text-xl" />}
                                    size="lg"
                                    type="email"
                                    value={requestEmail}
                                    onChange={(e) => setRequestEmail(e.target.value)}
                                    placeholder="Enter your email address"
                                    error={!!emailError}
                                    required
                                />
                                {emailError && (
                                    <motion.p 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className="mt-2 text-sm text-red-500 flex items-center gap-1"
                                    >
                                        <span className="inline-block w-1 h-1 rounded-full bg-red-500 mr-1" />
                                        {emailError}
                                    </motion.p>
                                )}
                                {requestSuccess && (
                                    <motion.div 
                                        initial={{ opacity: 0, y: 10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className="mt-4 p-4 bg-emerald-50 rounded-xl border border-emerald-100"
                                    >
                                        <div className="flex items-center gap-2 text-emerald-600">
                                            <IoShieldCheckmark className="w-5 h-5 flex-shrink-0" />
                                            <p className="text-sm font-medium">{requestSuccess}</p>
                                        </div>
                                    </motion.div>
                                )}
                            </div>

                            <div className="space-y-4">
                                <motion.button
                                    whileHover={{ scale: 1.01 }}
                                    whileTap={{ scale: 0.99 }}
                                    type="submit"
                                    className="w-full py-3 px-4 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-xl font-medium shadow-lg shadow-emerald-100/50 hover:shadow-xl hover:shadow-emerald-100/50 transition-all duration-200"
                                >
                                    Send Reset Instructions
                                </motion.button>

                                <div className="text-center">
                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        type="button"
                                        onClick={() => navigate('/signin')}
                                        className="text-sm text-emerald-600 hover:text-emerald-500 font-medium"
                                    >
                                        ← Back to Sign In
                                    </motion.button>
                                </div>
                            </div>
                        </form>
                    </div>
                </motion.div>

                {/* Right Panel - Decorative */}
                <motion.div 
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                    className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-emerald-500 to-green-500 p-12 flex-col items-center justify-center relative overflow-hidden order-1 lg:order-2"
                >
                    <div className="relative z-10 text-center">
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ delay: 0.3, duration: 0.5 }}
                            className="mb-8"
                        >
                            <IoShieldCheckmark className="w-24 h-24 text-white/90 mx-auto" />
                        </motion.div>
                        <h2 className="text-3xl font-bold text-white mb-4">Password Recovery</h2>
                        <p className="text-emerald-50 text-lg max-w-sm mx-auto">
                            We'll help you get back to exploring AI-powered possibilities with SparkAI
                        </p>
                    </div>

                    {/* Decorative elements */}
                    <div className="absolute inset-0 opacity-10">
                        <div className="absolute top-[10%] left-[20%] w-[200px] h-[200px] rounded-full border-[30px] border-white" />
                        <div className="absolute bottom-[-10%] right-[-5%] w-[300px] h-[300px] rounded-full border-[50px] border-white" />
                    </div>
                </motion.div>
            </div>
        </motion.main>
    );
}