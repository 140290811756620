
const fastModelMaxInputLength = 4096;
const smartModelMaxInputLength = 4096;
const apexModelMaxInputLength = 4096;

/**
 * Calculate the maximum input length for the fast model
 * @param {number} inputLength - The input length
 * 
 * @returns {boolean} - true if the input length is greater than the maximum input length for the fast model, false otherwise
 */
export function calcMaxInputLengthFastModel(inputLength) {
    console.log('Input length received:', inputLength);
    console.log('Fast model max input length:', fastModelMaxInputLength);
    return inputLength > fastModelMaxInputLength;
}

/**
 * Calculate the maximum input length for the smart model
 * @param {number} inputLength - The input length
 * 
 * @returns {boolean} - true if the input length is greater than the maximum input length for the smart model, false otherwise
 */
export function calcMaxInputLengthSmartModel(inputLength) {
    console.log('Input length received:', inputLength);
    console.log('Smart model max input length:', smartModelMaxInputLength);
    return inputLength > smartModelMaxInputLength;
}

/**
 * Calculate the maximum input length for the apex model
 * @param {number} inputLength - The input length
 * 
 * @returns {boolean} - true if the input length is greater than the maximum input length for the apex model, false otherwise
 */
export function calcMaxInputLengthApex(inputLength) {
    console.log('Input length received:', inputLength);
    console.log('Apex model max input length:', apexModelMaxInputLength);
    return inputLength > apexModelMaxInputLength;
}

/**
 * Calculate the maximum input length for a given model
 * @param {string} model - The model
 * @param {number} inputLength - The input length
 * 
 * @returns {boolean} - true if the input length is greater than the maximum input length for the model, false otherwise
 */
export function calcMaxInputLength(model, inputLength) {
    console.log('Model received:', model);
    console.log('Input length received:', inputLength);
    if (model === 'apex') {
        return calcMaxInputLengthApex(inputLength);
    } else if (model === 'smart') {
        return calcMaxInputLengthSmartModel(inputLength);
    } else {
        return calcMaxInputLengthFastModel(inputLength);
    }
}

/**
 * Replace custom Markdown
 * Will look for the following patterns:
 * - [n] -> <button className="reference">n</button>
 * - [n, m...] -> <button className="reference">n, m...</button>
 * n and m are only digits
 * you should not include the [] in the text
 */
export function replaceCustomMarkdown(text) {
    const text_with_references = text.replace(/\[(\d+(?:, \d+)*)\]/g, '<button className="reference">$1</button>');
    const text_p = "<div className='text-left text-gray-800'>" + text_with_references + "</p>";
    return text_p;
}