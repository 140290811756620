import { Badge, Tooltip } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { ChevronDownIcon, ShareIcon } from '@heroicons/react/20/solid';
import { FaBolt } from "react-icons/fa6";
import { LiaDnaSolid } from "react-icons/lia";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import axios from 'axios';
import { UserContext } from "../pages/Focus";
import { MainUserContext } from "../App";
import SparkAI_logo from '../assets/sparkai_new_only_logo.png';
import NewsModal from '../components/NewsModal';
import '../index.css';
import { IoNewspaper } from 'react-icons/io5';
import FocusLogo from '../assets/focus_only_logo_upscaled.png';

export default function TopHeader({isOpen, toggleDrawer, currentChatName, marginLeft}) {
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { model, setModel } = React.useContext(UserContext);
    const { sparkaiSidebarOpen, setSparkaiSidebarOpen } = React.useContext(UserContext);
    const [ modelMenuOpen, setModelMenuOpen ] = React.useState(false);
    const [closeTimeout, setCloseTimeout] = React.useState(null);
    const [shareMenuOpen, setShareMenuOpen] = React.useState(false);
    const [shareUrl, setShareUrl] = React.useState('');
    const [copied, setCopied] = React.useState(false);
    const [isShared, setIsShared] = React.useState(false);
    const [shareError, setShareError] = React.useState(null);
    const { currentChatId, messages } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);
    const [headerWidth, setHeaderWidth] = React.useState(0);
    const { showNewsModal, setShowNewsModal } = React.useContext(UserContext);
    const { hasUnreadNews, setHasUnreadNews } = React.useContext(UserContext);
    const [shareCloseTimeout, setShareCloseTimeout] = React.useState(null);


    // Check if chat is already shared when component mounts or when currentChatId changes
    React.useEffect(() => {
        const checkIfShared = async () => {
            if (!currentChatId) return;

            try {
                const response = await axios.get(`/api/chat/is-shared/${currentChatId}`);
                console.log("Response:", response.data);
                if (response.data.status === 'success') {
                    const isCurrentChatShared = response.data.is_shared;
                    setIsShared(isCurrentChatShared);
                    
                    if (isCurrentChatShared) {
                        const shareId = response.data.share_id;
                        setShareUrl(`${window.location.origin}/shared/${shareId}`);
                    }
                }
            } catch (err) {
                console.error('Failed to check share status:', err);
            }
        };

        checkIfShared();
    }, [currentChatId, userId]);

    const handleShareClick = async () => {
        console.log('Share button clicked. Current state:', { isShared, shareMenuOpen });
        
        if (isShared) {
            console.log('Chat is already shared, toggling menu');
            setShareMenuOpen(prev => !prev);
            return;
        }

        console.log('Attempting to share chat...');
        try {
            const response = await axios.post('/api/shared-chats', {
                conversation_id: currentChatId,
                created_by: userId,
                expires_at: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString() // 1 month from now
            });
            
            console.log('Share API response:', response.data);
            
            if (response.data.status === 'success') {
                setShareUrl(response.data.share_url);
                setIsShared(true);
                setShareMenuOpen(true);
                setShareError(null);
                console.log('Share successful, menu should open');
            } else {
                setShareError(response.data.message);
                console.log('Share failed:', response.data.message);
            }
        } catch (err) {
            console.error('Failed to share:', err);
            setShareError('Failed to share chat. Please try again.');
        }
    };

    const handleUnshare = async () => {
        try {
            const shareId = new URL(shareUrl).pathname.split('/').pop();
            const response = await axios.delete(`/api/shared-chats/${shareId}`, {
                data: { user_id: userId }
            });

            if (response.data.status === 'success') {
                setIsShared(false);
                setShareMenuOpen(false);
                setShareUrl('');
            }
        } catch (err) {
            console.error('Failed to unshare:', err);
            setShareError('Failed to unshare chat. Please try again.');
        }
    };

    const handleModelChange = (newModel) => {
        console.log("model changed to", newModel);
        setModel(newModel);
        setModelMenuOpen(false);
    }

    const handleMouseLeave = () => {
        const timeout = setTimeout(() => {
            setModelMenuOpen(false);
        }, 300);
        setCloseTimeout(timeout);
    };

    const handleMouseEnter = () => {
        if (closeTimeout) {
            clearTimeout(closeTimeout);
        }
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const handleShareMenuMouseLeave = () => {
        const timeout = setTimeout(() => {
            setShareMenuOpen(false);
            setCopied(false);
        }, 300);
        setShareCloseTimeout(timeout);
    };

    const handleShareMenuMouseEnter = () => {
        if (shareCloseTimeout) {
            clearTimeout(shareCloseTimeout);
        }
    };

    // Add click outside handler to close the menu
    const shareMenuRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (shareMenuRef.current && !shareMenuRef.current.contains(event.target)) {
                console.log('Click outside detected, closing share menu');
                setShareMenuOpen(false);
                setCopied(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    React.useEffect(() => {
        // Calculate the width of the header
        // We will use the width of the screen minus the marginLeft
        console.log("marginLeft:", marginLeft);
        console.log("window.innerWidth:", window.innerWidth);
        const headerWidth = window.innerWidth - marginLeft;
        setHeaderWidth(headerWidth);
        console.log("Header width:", headerWidth);
    }, [marginLeft]);

    return (
        <motion.div 
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex h-14 items-center fixed z-40 bg-gradient-to-b from-white via-white to-white/80 backdrop-blur-sm"
            style={{
                marginLeft,
                width: `calc(100% - ${marginLeft}px)`,
                right: 0
            }}
        >
            <div className="w-full px-4 flex items-center">
                {/* Left section - Chat name */}
                <div className="hidden md:flex items-center absolute left-4">
                    <div className="flex items-center gap-4">
                        {/* Chat name section */}
                        <div className="max-w-[500px] truncate">
                            <Tooltip title={currentChatName} placement="bottom">
                                <span className="text-gray-800 text-sm font-medium">
                                    {currentChatName}
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                {/* Center section - Model selector and Share button */}
                <div 
                    className="flex items-center gap-3 mx-auto"
                    style={{
                        transform: `translateX(calc(-${marginLeft/2}px))`
                    }}
                >
                    {/* Model Selector */}
                    <div className="relative group">
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={() => setModelMenuOpen(!modelMenuOpen)}
                            className="flex items-center gap-2 px-3 py-1.5 rounded-full transition-all duration-200 bg-gradient-to-r from-purple-100/90 to-indigo-100/90 hover:from-purple-200/90 hover:to-indigo-200/90"
                        >
                            <span className="text-sm font-semibold text-purple-700">
                                <span className="flex items-center gap-1">
                                    Focus Pro <FaBolt className="w-3.5 h-3.5" />
                                </span>
                            </span>
                            <ChevronDownIcon className="w-4 h-4 text-gray-400" />
                        </motion.button>

                        <AnimatePresence>
                            {modelMenuOpen && (
                                <motion.div
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseEnter={handleMouseEnter}
                                    className="absolute top-full mt-2 right-0 w-[450px] bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden p-2"
                                >
                                    {/* Focus Model */}
                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        disabled={messageIsLoading}
                                        onClick={() => handleModelChange('focus')}
                                        className="w-full text-left rounded-lg p-2 group transition-all duration-200"
                                    >
                                        <div className="relative overflow-hidden rounded-lg bg-gradient-to-r from-purple-100 via-indigo-50 to-purple-100 p-2.5 border border-purple-200/70">
                                            <div className="flex items-center justify-between relative z-10">
                                                <div className="flex items-center gap-3">
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-indigo-500 to-purple-600 font-semibold">
                                                            Focus Pro
                                                        </span>
                                                        <FaBolt className="w-3.5 h-3.5 text-purple-500" />
                                                    </div>
                                                    <p className="text-xs text-purple-700 font-medium">
                                                        Optimized for research paper analysis
                                                    </p>
                                                </div>
                                            </div>
                                            <motion.div
                                                className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(147,51,234,0.1),transparent_70%)]"
                                            />
                                            <motion.div
                                                className="absolute inset-0 bg-gradient-to-r from-purple-500/0 via-indigo-500/10 to-purple-500/0"
                                                animate={{
                                                    x: ['0%', '100%', '0%'],
                                                }}
                                                transition={{
                                                    duration: 3,
                                                    ease: "linear",
                                                    repeat: Infinity,
                                                }}
                                            />
                                        </div>
                                    </motion.button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    {/* Share Button */}
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleShareClick}
                        className={`items-center gap-2 px-3 py-1.5 rounded-full transition-all duration-200 ${
                            isShared 
                                ? 'bg-green-50/80 hover:bg-green-100/80 text-green-600' 
                                : 'bg-gray-100/80 hover:bg-gray-200/80 text-gray-600'
                        }
                        ${messages.length === 0 ? 'hidden' : 'flex'}
                        `}
                    >
                        <ShareIcon className="w-4 h-4" />
                        <span className="text-sm font-medium">
                            {isShared ? 'Shared' : 'Share'}
                        </span>
                    </motion.button>

                    {/* Share Menu */}
                    <AnimatePresence>
                        {shareMenuOpen && (
                            <motion.div
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 10 }}
                                onMouseLeave={handleShareMenuMouseLeave}
                                onMouseEnter={handleShareMenuMouseEnter}
                                className="absolute top-full right-4 mt-2 w-80 bg-white rounded-xl shadow-lg border border-gray-100 p-4"
                            >
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-gray-500 text-sm font-semibold">
                                        Share this chat
                                    </span>
                                    {isShared && (
                                        <button
                                            onClick={handleUnshare}
                                            className="text-xs text-red-500 hover:text-red-600"
                                        >
                                            Stop sharing
                                        </button>
                                    )}
                                </div>
                                {shareError && (
                                    <div className="text-red-500 text-xs">
                                        {shareError}
                                    </div>
                                )}
                                <div className="flex flex-row gap-2">
                                    <input
                                        type="text"
                                        value={shareUrl}
                                        readOnly
                                        className="flex-grow p-2 bg-gray-100 rounded-lg text-sm text-gray-600"
                                    />
                                    <button
                                        onClick={copyToClipboard}
                                        className="px-3 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-all duration-200"
                                    >
                                        {copied ? 'Copied!' : 'Copy'}
                                    </button>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                {/* Right section - Logo and News Button */}
                {!isSmallScreen && (
                    <div className="hidden md:flex items-center absolute right-4">
                        <motion.div
                            className="flex items-center gap-4"
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            {/* News Button */}
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={() => {
                                    setShowNewsModal(true);
                                    setHasUnreadNews(false);
                                }}
                                className="flex items-center gap-2 px-3 py-1.5 rounded-full transition-all duration-200 bg-gradient-to-r from-purple-50 to-cyan-50 hover:from-purple-100 hover:to-cyan-100 text-purple-600 border border-purple-200"
                            >
                                <IoNewspaper className="w-4 h-4" />
                                <span className="text-sm font-medium">Latest News</span>
                                {hasUnreadNews && (
                                    <div className="relative">
                                        <div className="w-2 h-2 rounded-full bg-purple-500" />
                                        <div 
                                            className="absolute inset-0 rounded-full bg-purple-500 animate-ping"
                                            style={{ animationDuration: '2s' }}
                                        />
                                    </div>
                                )}
                            </motion.button>

                            {/* Logo and Title */}
                            <div className="flex items-center gap-2">
                                <img
                                    src={FocusLogo}
                                    alt="Focus Logo"
                                    className="w-6 h-6"
                                />
                                <div className="flex flex-col mb-[5px]">
                                    <motion.span
                                        className="text-sm md:text-[0.7rem] font-bold bg-gradient-to-r from-purple-600 to-cyan-600 bg-clip-text text-transparent"
                                        initial={{ opacity: 0, x: -10 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ duration: 0.5, delay: 0.2 }}
                                    >
                                        SparkAI
                                    </motion.span>
                                    <motion.span
                                        className="text-xl md:text-[1.5rem] font-bold bg-gradient-to-r from-purple-600 to-cyan-600 bg-clip-text text-transparent mt-[-8px]"
                                        initial={{ opacity: 0, x: -10 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ duration: 0.5, delay: 0.3 }}
                                    >
                                        Focus
                                    </motion.span>
                                </div>
                                <motion.div
                                    initial={{ opacity: 0, scale: 0 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.3, delay: 0.4 }}
                                    className="hidden md:flex items-center"
                                >
                                    <span className="text-xs px-2 py-0.5 rounded-full bg-gradient-to-r from-purple-50 to-cyan-50 text-purple-600">
                                        AI Research Assistant
                                    </span>
                                </motion.div>
                            </div>
                        </motion.div>
                    </div>
                )}
            </div>
        </motion.div>
    );
}