import { LuAward } from "react-icons/lu"
import { IoSend } from "react-icons/io5"
import { CircularProgress } from "@mui/material"
import { motion } from "framer-motion"
import { SparkAICall } from "../components/helpers"
import React from "react"
import axios from "axios"
import SpinnerLoader from "../common_components/SpinnerLoader"
import { createFocusChat } from "../components/SourcesDrawerList"
import FocusLogo from '../assets/focus_only_logo_upscaled.png'

import { MainUserContext } from "../App"

function Article({ article, setCreatingFocusChat }) {

    const { userId } = React.useContext(MainUserContext);

    return (
        <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="flex flex-col justify-start items-start bg-purple-50 border-2 border-purple-800 rounded-lg p-4 w-[800px] max-w-[90vw] sm:max-w-[500px] md:max-w-[800px] min-h-[80px]
                hover:bg-purple-100 hover:border-purple-900 transition-all duration-300 ease-in-out cursor-pointer scale-100 hover:scale-105
                group relative hover:z-10"
        >
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full gap-2 sm:gap-0">
                <div className="flex flex-col sm:flex-row justify-between w-full gap-2">
                    <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center">
                        <span className="text-sm sm:text-md font-semibold break-words">{article.title}</span>
                        <span className={`text-xs px-2 py-1 rounded-full whitespace-nowrap ${article.isOpenAccess ? 'bg-green-100 text-green-800 border-2 border-green-300' : 'bg-red-100 text-red-800 border-2 border-red-300'}`}>
                            {article.isOpenAccess ? 'Open Access' : 'Not Open Access'}
                        </span>
                    </div>
                    <span className="text-xs text-gray-500">{article.year}</span>
                </div>
            </div>
            <span className="text-xs text-gray-500 break-words">
                {article.authors}
            </span>
            {article.isOpenAccess ? (
                <div className="absolute -bottom-8 left-0 bg-purple-800 text-white border-2 border-purple-900 shadow-lg rounded-lg px-3 py-1.5 text-sm 
                    opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out transform translate-y-1 group-hover:translate-y-0
                    flex items-center gap-2 hover:bg-purple-100 hover:border-purple-300 hover:text-purple-800 font-semibold"
                    onClick={async () =>{
                        setCreatingFocusChat(true);
                        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/qdrant/add_paper/', {
                            url: article.openAccessPdf.url ? article.openAccessPdf.url : article.url,
                            paper_id: article.paperId,
                            title: article.title,
                            authors: article.authors,
                            abstract: article.abstract
                        })
                        const cfc_response = await createFocusChat({
                            conversation_name: article.title,
                            user_id: userId,
                            paper_id: article.paperId,
                            title: article.title,
                            authors: article.authors,
                            abstract: article.abstract,
                            url: article.openAccessPdf.url ? article.openAccessPdf.url : article.url,
                        }, false)
                        console.log('Response from focus chat create:', response);
                        setCreatingFocusChat(false);
                    }
                }
                >
                    <LuAward className="w-4 h-4" />
                    Open in Focus
                </div>
            ) : (
                <div className="absolute -bottom-8 left-0 bg-gray-400 text-white border-2 border-gray-500 shadow-lg rounded-lg px-3 py-1.5 text-sm 
                    opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out transform translate-y-1 group-hover:translate-y-0
                    flex items-center gap-2 cursor-not-allowed"
                    title="This paper is not openly accessible to everyone"
                >
                    <LuAward className="w-4 h-4" />
                    Not Available in Focus
                </div>
            )}
        </motion.div>
    );
}

function ArticleSearchResults({ articles, submitted, isLoading, loadingMessage, setCreatingFocusChat }) {
    if (isLoading) {
        return (
            <div className="flex flex-col gap-4 justify-center items-center mt-10">
                <SpinnerLoader />
                <span className="text-sm text-gray-500">{loadingMessage}</span>
            </div>
        )
    }
    if (articles.length === 0 && submitted) {
        return (
            <div className="flex flex-col justify-start items-start gap-2 font-semibold text-purple-800 mt-10">
                <span className="text-sm text-gray-500">No articles found, try searching for a different paper</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-start items-start gap-2">
            {articles.map(article => (
                <Article key={article.paperId} article={article} setCreatingFocusChat={setCreatingFocusChat} />
            ))}
        </div>
    );
}

export default function NoPaper() {

    const [chatInput, setChatInput] = React.useState('');
    const [articles, setArticles] = React.useState([]);
    const [searchType, setSearchType] = React.useState('name');
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState('Searching for articles...');
    const [creatingFocusChat, setCreatingFocusChat] = React.useState(false);

    const processArticles = async (articles, setLoadingMessage) => {
        setLoadingMessage('Checking if articles are open access... (This may take a few seconds)');
        for (const article of articles) {
            console.log("Fetching Article");
            article.authors = article.authors ? article.authors.map(author => author.name).join(', ') : '';
            if (article.openAccessPdf !== null) {
                const pdfProxyUrl = `${process.env.REACT_APP_API_URL}/api/focus/pdf?url=${encodeURIComponent(article.openAccessPdf.url)}`;
                console.log("PDF Proxy URL: ", pdfProxyUrl);
                // Check if the PDF is actually accessible
                try {
                    console.log("Fetching PDF URL: ", article.openAccessPdf.url);
                    const response = await axios.get(pdfProxyUrl);
                    console.log("Response: ", response);
                    article.isOpenAccess = response.status === 200;
                } catch (error) {
                    console.log("Error: ", error);
                    article.isOpenAccess = false;
                }
            } else {
                console.log("No PDF URL");
                article.isOpenAccess = false;
            }
        }
        return articles;
    }

    const handleSubmitName = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://api.semanticscholar.org/graph/v1/paper/search?query=${encodeURIComponent(chatInput)}&offset=0&limit=3&fields=title,url,abstract,authors,year,openAccessPdf`);
            const jsonResponse = await response.json();
            const processedArticles = await processArticles(jsonResponse.data, setLoadingMessage);
            setArticles(processedArticles);
            console.log(processedArticles);
        } catch (error) {
            console.error('Error fetching articles:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitDoi = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://api.semanticscholar.org/graph/v1/paper/DOI:${encodeURIComponent(chatInput)}?fields=title,url,abstract,authors,year,openAccessPdf`);
            const article = await response.json();
            const processedArticles = await processArticles([article], setLoadingMessage);
            setArticles(processedArticles);
            console.log(processedArticles);
        } catch (error) {
            console.error('Error fetching article:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitArxiv = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://api.semanticscholar.org/graph/v1/paper/ARXIV:${encodeURIComponent(chatInput)}?fields=title,url,abstract,authors,year,openAccessPdf`);
            const article = await response.json();
            const processedArticles = await processArticles([article], setLoadingMessage);
            setArticles(processedArticles);
            console.log(processedArticles);
        } catch (error) {
            console.error('Error fetching article:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        setLoadingMessage('Searching for articles...');
        setLoading(true);
        if (searchType === 'name') {
            handleSubmitName(e);
        } else if (searchType === 'doi') {
            handleSubmitDoi(e);
        } else if (searchType === 'arxiv') {
            handleSubmitArxiv(e);
        }
        setLoading(false);
    }

    const [textareaHeight, setTextareaHeight] = React.useState(50); // Default height of the textarea
    const textareaRef = React.useRef(null);

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to calculate correct scrollHeight
            const newHeight = textareaRef.current.scrollHeight;
            // Cap the height at 150px
            if (newHeight > 125) {
                textareaRef.current.style.height = "125px"; // Maximum height
                textareaRef.current.style.overflowY = "auto"; // Add scrollbar when exceeding max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(150); // Update height state for animation
            } else {
                textareaRef.current.style.height = `${newHeight}px`; // Allow auto-resizing below 150px
                textareaRef.current.style.overflowY = "hidden"; // Hide scrollbar when below max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(newHeight); // Update height state for animation
            }
        }
    }, [chatInput]);    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent new line on Enter
            if (searchType === 'name') {
                handleSubmitName(e); // Trigger form submission
            } else if (searchType === 'doi') {
                handleSubmitDoi(e); // Trigger form submission
            } else if (searchType === 'arxiv') {
                handleSubmitArxiv(e); // Trigger form submission
            }
        }
    };


    if (creatingFocusChat) {
        return (
            <div className="flex flex-col justify-start items-center h-screen gap-3 pt-36">
                <SpinnerLoader />
                <span className="text-sm text-gray-500">Loading your research space...</span>
            </div>
        )
    }

    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col justify-start items-center min-h-screen gap-6 pt-24 px-4"
        >
            {/* Header Section */}
            <motion.div 
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col justify-center items-center gap-4 text-center"
            >
                <div className="relative">
                    <img 
                        className='w-12 h-12 md:w-16 md:h-16'
                        src={FocusLogo} 
                        alt="Focus_logo"
                    />
                    <motion.div 
                        animate={{ 
                            scale: [1, 1.2, 1],
                            opacity: [0.5, 0.8, 0.5]
                        }}
                        transition={{ 
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                        className="absolute -inset-4 bg-gradient-to-r from-purple-100 to-cyan-100 rounded-full -z-10"
                    />
                </div>
                <h1 className="text-2xl md:text-4xl font-bold text-center bg-gradient-to-r from-purple-600 to-cyan-600 bg-clip-text text-transparent">
                    Discover Research Papers
                </h1>
                <p className="text-sm md:text-base text-gray-600 max-w-lg">
                    Search for academic papers and engage with them using AI-powered analysis
                </p>
            </motion.div>

            {/* Search Type Selector */}
            <div className="flex gap-3 p-1 bg-gray-100 rounded-xl">
                {[
                    { id: 'name', label: 'Name' },
                    { id: 'doi', label: 'DOI' },
                    { id: 'arxiv', label: 'arXiv' }
                ].map((type) => (
                    <motion.button
                        key={type.id}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => setSearchType(type.id)}
                        className={`px-6 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                            searchType === type.id 
                                ? 'bg-white text-purple-600 shadow-sm' 
                                : 'text-gray-600 hover:text-purple-600'
                        }`}
                    >
                        {type.label}
                    </motion.button>
                ))}
            </div>

            {/* Search Form */}
            <div className="w-full max-w-2xl">
                <form 
                    onSubmit={handleSubmit}
                    className="relative group"
                >
                    <motion.div
                        className="absolute inset-0 rounded-2xl bg-gradient-to-r from-purple-500/20 via-cyan-500/20 to-purple-500/20 opacity-0 group-hover:opacity-100 blur-md transition-all duration-300"
                        animate={{
                            scale: [1, 1.02, 1],
                        }}
                        transition={{
                            duration: 4,
                            repeat: Infinity,
                        }}
                    />
                    <div className="relative bg-white rounded-2xl border border-gray-200 focus-within:border-purple-500 hover:border-purple-500/50 transition-all duration-300 shadow-sm">
                        <div className="px-4 pt-2.5 pb-1 flex items-center gap-2 text-xs text-gray-500">
                            <div className="flex items-center gap-1.5">
                                <div className="w-2 h-2 rounded-full bg-purple-500" />
                                <span className="font-medium">Focus Research</span>
                            </div>
                            •
                            <span>Enter {searchType === 'name' ? 'paper title' : searchType.toUpperCase()}</span>
                        </div>

                        <div className="relative flex items-end">
                            <textarea
                                ref={textareaRef}
                                required
                                value={chatInput}
                                onChange={(e) => setChatInput(e.target.value)}
                                onKeyDown={handleKeyDown}
                                disabled={loading}
                                placeholder={loading ? "Searching..." : `Search by ${searchType === 'name' ? 'paper title' : searchType.toUpperCase()}`}
                                className="w-full pl-4 pr-16 py-3 outline-none rounded-2xl resize-none placeholder:text-gray-400"
                                style={{
                                    minHeight: '56px',
                                    maxHeight: '200px'
                                }}
                            />
                            
                            <div className="absolute right-2 bottom-2.5 flex items-center gap-2">
                                {chatInput.length > 0 && (
                                    <motion.div 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className="text-xs text-gray-400"
                                    >
                                        {chatInput.length}/4000
                                    </motion.div>
                                )}
                                
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    type="submit"
                                    disabled={loading}
                                    className={`relative flex-shrink-0 p-2 rounded-xl transition-all duration-300 ${
                                        loading
                                            ? 'bg-gray-100 text-gray-400'
                                            : 'bg-gradient-to-r from-purple-500 to-cyan-500 text-white shadow-sm hover:shadow-md hover:shadow-purple-500/20'
                                    }`}
                                >
                                    {loading ? (
                                        <motion.div
                                            animate={{ rotate: 360 }}
                                            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                                        >
                                            <CircularProgress size="sm" thickness={2} />
                                        </motion.div>
                                    ) : (
                                        <IoSend className="w-4 h-4" />
                                    )}
                                </motion.button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* Results Section */}
            <ArticleSearchResults
                articles={articles}
                submitted={submitted}
                isLoading={loading}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                setLoadingMessage={setLoadingMessage}
                setCreatingFocusChat={setCreatingFocusChat}
            />
        </motion.div>
    );
}