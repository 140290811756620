import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import SparkAI_logo from '../assets/sparkai_new_only_logo.png';
import '../App.css';
import ChatList from './ChatButton';
import ChatSearch from './ChatSearch';
import { LuSettings2 } from "react-icons/lu";
import { AiOutlinePlus } from "react-icons/ai";
import '../index.css';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';
import { FaUserCircle, FaCog, FaQuestionCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { IoShieldCheckmark } from "react-icons/io5";
import { Tooltip } from '@mui/material';

import { UserContext } from '../pages/Chat';

import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import { Button } from '@mui/base';

import { MainUserContext } from '../App';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from "framer-motion";

// Helper component for menu items - renamed to CustomMenuItem
function CustomMenuItem({ icon, text, onClick, danger }) {
    return (
        <motion.button
            whileHover={{ scale: 1.02, x: 4 }}
            whileTap={{ scale: 0.98 }}
            onClick={onClick}
            className={`w-full flex items-center gap-3 px-3 py-2 rounded-lg text-sm ${
                danger 
                    ? 'text-red-600 hover:bg-red-50' 
                    : 'text-gray-700 hover:bg-gray-50'
            }`}
        >
            <span className={danger ? 'text-red-500' : 'text-gray-500'}>
                {icon}
            </span>
            {text}
        </motion.button>
    );
}

export function ProfileMenu({ setSidebarOpen, sidebarOpen }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const { setLoggedIn, user } = React.useContext(MainUserContext);
    const menuRef = React.useRef();
    const navigate = useNavigate();

    const logOut = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/logout', {withCredentials: true})
            .then(response => {
                window.open('https://ederspark.com/?page=platform', '_self');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
        setLoggedIn(false);
    };

    const handleProfileClick = () => {
        if (!sidebarOpen) {
            setSidebarOpen(true);
        }
        setIsOpen(!isOpen);
    };

    // Close menu when clicking outside
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="relative" ref={menuRef}>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleProfileClick}
                className="relative flex items-center gap-2 p-1 rounded-full hover:bg-gray-50 transition-colors"
            >
                <div className="relative">
                    <div className="w-8 h-8 rounded-full bg-gradient-to-r from-emerald-200 to-green-200 flex items-center justify-center text-emerald-700 font-medium">
                        {user?.mail?.charAt(0).toUpperCase()}
                    </div>
                    <div className="absolute bottom-0 right-0 w-3 h-3 bg-emerald-500 rounded-full border-2 border-white" />
                </div>
            </motion.button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.95, y: 10 }}
                        animate={{ opacity: 1, scale: 1, y: 0 }}
                        exit={{ opacity: 0, scale: 0.95, y: 10 }}
                        transition={{ duration: 0.2 }}
                        className="fixed left-3 bottom-20 w-64 bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden z-[9999]"
                    >
                        {/* Profile Header */}
                        <div className="p-4 bg-gradient-to-r from-emerald-50 to-green-50">
                            <div className="flex items-center gap-3">
                                <div className="w-10 h-10 rounded-full bg-gradient-to-r from-emerald-200 to-green-200 flex items-center justify-center text-emerald-700 font-medium text-lg">
                                    {user?.mail?.charAt(0).toUpperCase()}
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-700 truncate">
                                        {user?.mail}
                                    </p>
                                    <div className="flex items-center gap-1">
                                        <IoShieldCheckmark className="w-3 h-3 text-emerald-500" />
                                        <p className="text-xs text-emerald-600">Active Account (Beta)</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Menu Items */}
                        <div className="p-2">
                            <CustomMenuItem 
                                icon={<FaUserCircle />} 
                                text="My Account" 
                                onClick={() => navigate('/account')}
                            />
                            <CustomMenuItem 
                                icon={<FaQuestionCircle />} 
                                text="Help & Support" 
                                onClick={() => window.open('https://ederspark.com/support', '_blank')}
                            />
                            <div className="h-px bg-gray-100 my-2" />
                            <CustomMenuItem 
                                icon={<MdLogout />} 
                                text="Log Out" 
                                onClick={logOut}
                                danger
                            />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

function DrawerHeader() {

    const { user } = React.useContext(MainUserContext);
    const [mail, setMail] = React.useState('error loading mail');

    React.useEffect(() => {

        if (user) {
            setMail(user.mail);
        }
    }, [user]);

    return (
        <div
            className="flex flex-row justify-between items-center px-2"
        >
            <div 
                className="flex flex-row justify-start items-center gap-3"
            >
                <img src={SparkAI_logo} alt="SparkAI_logo" 
                    className="h-6 w-6"
                />
                <text
                    className="text-lg font-bold"
                >
                    Chats
                </text>
            </div>
            <div
                className="hidden md:flex flex-row justify-end items-center gap-1 text-gray-500"
            >
                <text
                    className="text-xs"
                >{mail}</text>
                <ProfileMenu/>
            </div>
        </div>
    );
}

function NewChatButton() {

    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);    
    const { messages, setMessages } = React.useContext(UserContext);
    const { setCurrentChatId, setCurrentChatName } = React.useContext(UserContext);

    return (
        <>
        <div
            className="w-full flex justify-center pb-3"
        >
            <Button 
                    className="bg-emerald-500 hover:bg-emerald-200 w-11/12 h-10 rounded-md shadow-inner"
                onClick={() => {
                    setMessages([]);
                    setCurrentChatName('');
                    setCurrentChatId(null);
                }}
                disabled={messageIsLoading}
                >
                <text
                    className="text-white font-bold"
                >
                    New chat
                </text>
                
            </Button>
        </div>
        </>
    );
}

function DrawerBody({ drawerSize, setCurrentChatName }) {
    const [filterName, setFilterName] = React.useState('');
    return (
        <div 
            className={`w-[${drawerSize}] justify-normal rounded-md flex flex-col bg-green-100 pt-4 shadow-inner h-full`}
        >
            <ChatSearch setFilterName={setFilterName}/>
            <div
                className="flex flex-col justify-between h-full"
            >
                <ChatList
                    filterName={filterName} setCurrentChatName={setCurrentChatName}/>
                <NewChatButton />
            </div>
        </div>
    );
}

export default function PersistentDrawer({ isOpen, drawerSize, drawerMargin, setCurrentChatName, setLoggedIn, setSidebarOpen, sidebarOpen }) {

    // if is on mobile, set drawerSize to 10
    if (window.innerWidth < 768) {
        drawerSize = window.innerWidth - 20;
    }

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={isOpen}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    boxShadow: 'none', // Remove the shadow
                    border: 'none', // Remove the border if there's any
                    backgroundColor: 'transparent', // Transparent background
                    zIndex: 1001, // Set the z-index to 9999
                },
            }}
        >
            <div
                className={`flex flex-col gap-3 w-[${drawerSize}] bg-transparent h-full rounded-md m-[${drawerMargin}] persistent-drawer`} 
            style={{ width: drawerSize + 5, display: 'flex', flexDirection: 'column', gap: '10px', backgroundColor: 'transparent', height:'100vh', borderRadius: '25px', margin: drawerMargin}}>
                <DrawerHeader drawerSize={drawerSize} setLoggedIn={setLoggedIn}/>
                <DrawerBody drawerSize={drawerSize} setCurrentChatName={setCurrentChatName}/>
            </div>
        </Drawer>
    );
}